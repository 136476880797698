const JWT_KEY = "token"
const ROLE_KEY = "role"
export class LocalItem {
  key: string
  public constructor(key: string) {
    this.key = key
  }
  get(): string | null {
    return localStorage.getItem(this.key)
  }
  set(data: string): void {
    localStorage.setItem(this.key, data)
  }
  reset(): void {
    localStorage.removeItem(this.key)
  }
}

export const LocalToken = new LocalItem(JWT_KEY)
export const LocalRole = new LocalItem(ROLE_KEY)
