import React, { useEffect, useState } from "react"
import { Drawer, Box, Typography, useTheme, Theme, alpha } from "@mui/material"
import { useAuthContext } from "../services/auth"
import { UserRole } from "../services/auth/auth.types"
import { StylelessLink } from "./Util"
import { useDirectAPI } from "../services/api/hooks/useDirectAPI"
import { useHistory } from "react-router-dom"
import { DirectQueryType, DirectType, PagedDirectResponse } from "../services/api/direct"
import { httpClient } from "../services/http"
import { useThemeContext } from "../services/ThemeContext"

import { ReactComponent as HyrisLogo } from "../assets/svg/hyris-full.svg"
import { ReactComponent as HouseIcon } from "../assets/svg/house.svg"
import { ReactComponent as LoginIcon } from "../assets/svg/log-in.svg"
import { ReactComponent as LogoutIcon } from "../assets/svg/log-out.svg"
import { ReactComponent as PlayIcon } from "../assets/svg/play.svg"
import { ReactComponent as VideoPlayerIcon } from "../assets/svg/video-player.svg"
import { ReactComponent as FilmIcon } from "../assets/svg/film.svg"
import { ReactComponent as GearIcon } from "../assets/svg/gear.svg"
import { ReactComponent as WikiIcon } from "../assets/svg/wikipedia.svg"
import { ReactComponent as CalendarIcon } from "../assets/svg/calendar.svg"
import { ReactComponent as MailIcon } from "../assets/svg/mail.svg"
import { ReactComponent as HistoryIcon } from "../assets/svg/history.svg"
import { ReactComponent as HeartIcon } from "../assets/svg/heart.svg"
import { ReactComponent as PortfolioIcon } from "../assets/svg/portfolio.svg"
import { ReactComponent as ContactIcon } from "../assets/svg/chat.svg"
import { ReactComponent as EyeIcon } from "../assets/svg/eye.svg"
import { ReactComponent as TrophyIcon } from "../assets/svg/trophy.svg"
import { useSnackbarContext } from "../services/snackbar"
import axios from "axios"

interface AuthOption {
  requires?: UserRole[]
  noAuthOnly?: boolean
}

interface SubLink extends AuthOption {
  title: string
  url: string
  external?: boolean
  requires?: UserRole[]
  noAuthOnly?: boolean
}

class NavIcon {
  src?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  type: "fill" | "stroke" = "fill"
}

class NavOption implements AuthOption {
  type = "navOption"
  title = ""
  url?: string
  external?: boolean
  iconUrl?: string
  subLinks?: SubLink[]
  requires?: UserRole[]
  noAuthOnly?: boolean
  action?: () => void
  icon?: NavIcon
  hidden?: boolean = false
  bgColor?: string = "transparent"
  hoverBgColor?: string
}

class Section implements AuthOption {
  type = "section"
  title = ""
  links: NavOption[] = []
  requires?: UserRole[]
  noAuthOnly?: boolean
  hidden?: boolean = false
}

const appears = (element: AuthOption | SubLink, role: UserRole | null, isAuth: boolean): boolean => {
  return (
    (!element.noAuthOnly && (!element.requires || (role !== null && element.requires.some((r) => r === role)))) ||
    (element.noAuthOnly !== undefined && !isAuth)
  )
}

const navStructure = (
  latestDirects: DirectType[],
  handleLogoutClick: () => void,
  theme: Theme,
  hideLive = true,
  gpa = true
): (NavOption | Section)[] => [
  {
    type: "navOption",
    title: "Accueil",
    url: "/",
    icon: { src: HouseIcon, type: "fill" },
  },
  {
    type: "navOption",
    title: "Connexion",
    url: "/login",
    noAuthOnly: true,
    icon: { src: LoginIcon, type: "stroke" },
  },
  {
    type: "navOption",
    title: "Nos vidéos publiques",
    url: "/videos",
    noAuthOnly: true,
    icon: { src: VideoPlayerIcon, type: "stroke" },
  },
  {
    type: "section",
    title: "CONTENU",
    requires: [UserRole.USER, UserRole.HYRIS, UserRole.GEEK],
    links: [
      {
        type: "navOption",
        title: "DT EN COURS",
        url: "/dt",
        bgColor: theme.palette.mode === "light" ? "#ff3333" : "darkred",
        hoverBgColor: "red",
        hidden: hideLive,
        icon: { src: PlayIcon, type: "fill" },
      },
      {
        type: "navOption",
        title: "Dernières vidéos",
        url: "/videos",
        icon: { src: VideoPlayerIcon, type: "stroke" },
      },
      {
        type: "navOption",
        title: "Derniers DT",
        url: "/directs",
        icon: { src: FilmIcon, type: "fill" },
        subLinks: latestDirects
          .map((direct) => ({
            title: direct.title,
            url: "/direct/" + direct.id,
          }))
          .concat({
            title: "... tous nos DT",
            url: "/directs",
          }),
      },
      {
        type: "navOption",
        title: "Les plus vues",
        url: "/mostViewed",
        icon: { src: EyeIcon, type: "fill" },
      },
      {
        type: "navOption",
        title: "Les plus likées",
        url: "/mostLiked",
        icon: { src: TrophyIcon, type: "fill" },
      },
    ],
  },
  {
    type: "section",
    title: "ESPACE PERSO",
    requires: [UserRole.USER, UserRole.HYRIS, UserRole.GEEK],
    links: [
      {
        type: "navOption",
        title: "Historique",
        url: "/history",
        icon: { src: HistoryIcon, type: "stroke" },
      },
      {
        type: "navOption",
        title: "Vidéos likées",
        url: "/liked",
        icon: { src: HeartIcon, type: "fill" },
      },
    ],
  },
  {
    type: "section",
    title: "ESPACE MEMBRE",
    requires: [UserRole.HYRIS, UserRole.GEEK],
    links: [
      {
        type: "navOption",
        title: "Administration",
        icon: { src: GearIcon, type: "fill" },
        subLinks: [
          {
            title: "Ajouter une vidéo",
            url: "/addVideo",
          },
          {
            title: "Vidéos en attente",
            url: "/pending",
          },
          {
            title: "Ajouter un DT",
            url: "/addDirect",
            requires: [UserRole.GEEK],
          },
          {
            title: "Paramètres",
            url: "/settings",
            requires: [UserRole.GEEK],
          },
          {
            title: "Base de données",
            url: `/db/`,
            external: true,
            requires: [UserRole.GEEK],
          },
        ],
      },
      {
        type: "navOption",
        title: "Kiwi",
        url: "https://kiwi.hyris.tv",
        external: true,
        icon: { src: WikiIcon, type: "fill" },
        hidden: gpa,
      },
      {
        type: "navOption",
        title: "Tosma",
        url: "https://tosma.hyris.tv",
        external: true,
        icon: { src: CalendarIcon, type: "stroke" },
      },
      {
        type: "navOption",
        title: "Mail",
        url: "https://mail.hyris.tv",
        external: true,
        icon: { src: MailIcon, type: "fill" },
        hidden: gpa,
      },
    ],
  },
  {
    type: "section",
    title: "L'ASSO",
    links: [
      {
        type: "navOption",
        title: "Nous Contacter",
        url: "/contact",
        icon: { src: ContactIcon, type: "fill" },
      },
      {
        type: "navOption",
        title: "Portfolio",
        url: "/portfolio",
        icon: { src: PortfolioIcon, type: "fill" },
        hidden: true,
      },
    ],
    hidden: true,
  },
  {
    type: "navOption",
    title: "Nous Contacter",
    url: "/contact",
    icon: { src: ContactIcon, type: "fill" },
  },
  {
    type: "navOption",
    title: "Déconnexion",
    requires: [UserRole.USER, UserRole.HYRIS, UserRole.GEEK],
    action: handleLogoutClick,
    icon: { src: LogoutIcon, type: "stroke" },
  },
]

interface RenderNavOptionArguments {
  navOption: NavOption
  role: UserRole | null
  isAuth: boolean
}

const RenderNavOption = ({ navOption, role, isAuth }: RenderNavOptionArguments): React.ReactElement => {
  const theme = useTheme()
  return (
    <Box
      onClick={navOption.action || undefined}
      sx={{ cursor: navOption.action || navOption.url ? "pointer" : undefined }}
    >
      <StylelessLink to={navOption.url || undefined} external={navOption.external}>
        <Box
          color="inherit"
          style={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "flex-start" }}
          sx={{
            padding: 1,
            flexShrink: 0,
            textAlign: "left",
            transition: ".3s",
            "&:hover":
              navOption.url || navOption.action
                ? { bgcolor: navOption.hoverBgColor || theme.palette.element.sideBar.buttonHover }
                : {},
          }}
          bgcolor={navOption.bgColor}
        >
          {navOption.icon && SideMenuIcon(navOption.icon)}
          <Typography fontWeight="bold" lineHeight={1.8}>
            {navOption.title}
          </Typography>
        </Box>
      </StylelessLink>
      {navOption.subLinks && (
        <Box>
          {navOption.subLinks
            .filter((link) => appears(link, role, isAuth))
            .map((link, k) => (
              <StylelessLink key={k} to={link.url} external={link.external}>
                <Box
                  color="inherit"
                  style={{ justifyContent: "flex-start" }}
                  padding={0.5}
                  paddingLeft={1}
                  sx={{
                    ml: 3,
                    borderLeft: `1px solid ${theme.palette.element.sideBar.border}`,
                    flexShrink: 0,
                    textAlign: "left",
                    transition: ".3s",
                    "&:hover": {
                      borderLeft: "1px solid white",
                      borderColor: theme.palette.element.sideBar.buttonHoverBorder,
                      bgcolor: theme.palette.element.sideBar.buttonHover,
                    },
                  }}
                >
                  <Typography>{link.title}</Typography>
                </Box>
              </StylelessLink>
            ))}
        </Box>
      )}
    </Box>
  )
}

interface HomeDisplayResponse {
  homeDisplay: number
}

interface SideMenuProps {
  open: boolean
  onClose: () => void
}

export const SideMenu = ({ open, onClose }: SideMenuProps): React.ReactElement => {
  const [direct, setDirect] = useState<DirectType | null>(null)
  const [homeDisplay, setHomeDisplay] = useState(0)
  const [clicks, setClicks] = useState(0)
  const { toggleTheme, mode } = useThemeContext()
  const { open: sbOpen } = useSnackbarContext()
  const theme = useTheme()
  const { role, isAuth, logout, username } = useAuthContext()
  const history = useHistory()
  const { directsData: directs, loading: directsLoading } = useDirectAPI({ constraint: DirectQueryType.LATEST })

  const handleLogoutClick = () => {
    if (isAuth()) {
      logout()
    }
    history.push("/login")
  }

  const handleLogoClick = () => {
    if (clicks >= 9) {
      setClicks(0)
      mode ? sbOpen("Mode jour désactivé !", "info") : sbOpen("Mode jour activé !", "info")
      toggleTheme()
    } else {
      setClicks(clicks + 1)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { homeDisplay: hd } = await httpClient.instance.get<HomeDisplayResponse>(`homeDisplay`)
        setHomeDisplay(hd)
        if (hd) {
          const response = await httpClient.instance.get<PagedDirectResponse>(`currentDirect`)
          setDirect((response.directs && response.directs[0]) || null)
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.status === 401) {
            return
          }
        }
        console.log(error)
      }
    }
    if (isAuth()) fetchData()
  }, [])

  return (
    <React.Fragment>
      <Drawer open={open} onClose={onClose} PaperProps={{ sx: { backgroundColor: "transparent" } }}>
        <Box
          sx={{
            bgcolor: alpha(theme.palette.element.sideBar.background, theme.palette.element.sideBar.alpha),
            backdropFilter: "blur(10px)",
            color: theme.palette.element.sideBar.text,
            overflowY: "auto",
            maxWidth: "250px",
          }}
          height="100%"
        >
          <Box sx={{ borderBottom: "1px solid gray" }} width="100%" padding={2} onClick={handleLogoClick}>
            <HyrisLogo fill={theme.palette.primary.contrastText} height="120" width="120" />
          </Box>
          {navStructure(
            !directsLoading ? directs.slice(0, 5) : [],
            handleLogoutClick,
            theme,
            !(direct && homeDisplay === 2),
            !isAuth() || username === "gpa"
          )
            .filter((element) => appears(element, role, isAuth()))
            .filter((element) => !element.hidden)
            .map((element, k) => (
              <Box key={k}>
                {element.type === "navOption" ? (
                  <RenderNavOption navOption={element} role={role} isAuth={isAuth()} />
                ) : (
                  <Box
                    color="inherit"
                    style={{ justifyContent: "flex-start" }}
                    sx={{
                      flexShrink: 0,
                      textAlign: "left",
                      my: 3,
                    }}
                  >
                    <Box sx={{ pl: 1 }}>
                      <Typography sx={{ color: "gray", fontWeight: "bold" }}>{element.title}</Typography>
                    </Box>
                    {(element as Section).links
                      .filter((link) => !link.hidden)
                      .map((link, k) => (
                        <RenderNavOption navOption={link} role={role} isAuth={isAuth()} key={k} />
                      ))}
                  </Box>
                )}
              </Box>
            ))}
        </Box>
      </Drawer>
    </React.Fragment>
  )
}

const SideMenuIcon = (Icon: NavIcon): React.ReactElement => {
  const theme = useTheme()
  return Icon.src ? (
    <Icon.src
      height="30px"
      width="30px"
      style={{
        marginRight: "5px",
        fill: Icon.type === "fill" ? theme.palette.element.sideBar.icon : "none",
        stroke: Icon.type === "stroke" ? theme.palette.element.sideBar.icon : "none",
      }}
    />
  ) : (
    <></>
  )
}
