import React from "react";
import MojsExample from "../assets/animations/hyrisLoader";

export const Loading = () : React.ReactElement => {
  return (
    <>
      <MojsExample duration={2000} />
    </>
  );
};
