import React, { useState } from "react"

import { Footer } from "../components/Footer"
import { HeaderBar } from "../components/HeaderBar"
import { Main, PageTitle } from "../components/StyledElements"
import { useTagAPI } from "../services/api/hooks/useTagAPI"
import { VideoQueryType } from "../services/api/video"
import { PagedVideoSuggestions } from "../components/VideoSuggestions"
import { ReactComponent as FilterIcon } from "../assets/svg/filter.svg"
import { Box, Chip, useTheme } from "@mui/material"
import { TagType } from "../services/api/tag"

export const GenericVideoListPage = (constraint: VideoQueryType, title: string) => (): React.ReactElement => {
  const [tags, setTags] = useState<TagType[]>([])
  const [showTags, setShowTags] = useState(false)
  const { tagsData } = useTagAPI({})
  const theme = useTheme()

  const addTag = (tag: TagType) => {
    setTags([...tags, tag])
  }

  const removeTag = (id: number) => {
    setTags(tags.filter((tag) => tag.id != id))
  }

  return (
    <React.Fragment>
      <HeaderBar />
      <Main>
        <PageTitle>{title}</PageTitle>
        <Box padding={2}>
          <Box minHeight="50px" display="flex" flexDirection="row" alignItems="center">
            <FilterIcon
              height="30px"
              width="30px"
              style={{
                stroke: showTags ? theme.palette.secondary.main : theme.palette.soft,
                transition: ".3s",
                cursor: "pointer",
              }}
              onClick={() => {
                if (tags.length) setTags([])
                setShowTags(!showTags)
              }}
            />
            {showTags ? (
              tagsData.map((tag) => (
                <Chip
                  key={tag.id}
                  color={tags.some((t) => t.id === tag.id) ? "secondary" : "primary"}
                  label={tag.name}
                  onClick={tags.some((t) => t.id === tag.id) ? () => removeTag(tag.id) : () => addTag(tag)}
                  sx={{ marginX: 1 }}
                />
              ))
            ) : (
              <></>
            )}
          </Box>
          <PagedVideoSuggestions constraint={constraint} tagFilter={tags} md={3} pageSize={48} />
        </Box>
      </Main>
      <Footer />
    </React.Fragment>
  )
}

export const Videos = GenericVideoListPage(VideoQueryType.LATEST, "Nos dernières vidéos")

export const MostViewedVideos = GenericVideoListPage(VideoQueryType.MOST_VIEWED, "Les plus vues")

export const MostLikedVideos = GenericVideoListPage(VideoQueryType.MOST_LIKED, "Les plus likées")

export const LikedVideos = GenericVideoListPage(VideoQueryType.LIKED, "Mes vidéos likées")

export const History = GenericVideoListPage(VideoQueryType.HISTORY, "Mon historique")
