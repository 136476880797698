import { Box, CardMedia, styled, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { DirectType } from "../services/api/direct"
import AspectRatioBox from "./AspectRatioBox"
import DefaultPoster from "../assets/default-poster.jpg"
import { FormatDate, StylelessLink } from "./Util"
import { HoverOverlay, Overlay } from "./StyledElements"

export const DirectCardMedia = styled(CardMedia)({
  paddingTop: "141.42%",
})

interface DirectPosterProps {
  direct: DirectType
}
export const DirectPoster = ({ direct }: DirectPosterProps): React.ReactElement => {
  return (
    <Box mb={2}>
      <StylelessLink to={"/direct/" + direct.id}>
        <HoverOverlay sx={{ display: "flex", flexDirection: "column", height: "fit-content" }}>
          <AspectRatioBox ratio={70 / 99}>
            <DirectPosterImage direct={direct} />
            <Overlay className="overlay" />
          </AspectRatioBox>
          <Box mt={1} sx={{ position: "relative", zIndex: 2 }}>
            <Typography marginLeft={1} noWrap variant="h5" fontSize={"1.2rem"} textOverflow="ellipsis">
              {direct.title}
            </Typography>
            {direct.date && (
              <Typography
                marginLeft={1}
                noWrap
                variant="subtitle1"
                fontSize={".8rem"}
                color={"gray"}
                textOverflow="ellipsis"
              >
                {FormatDate(direct.date)}
              </Typography>
            )}
          </Box>
        </HoverOverlay>
      </StylelessLink>
    </Box>
  )
}

interface DirectPosterImageProps {
  direct: DirectType | null
}

export const DirectPosterImage = ({ direct }: DirectPosterImageProps): React.ReactElement => {
  const [posterSource, setPosterSource] = useState<string | null>(null)

  useEffect(() => {
    if (!direct) return
    const fetchPoster = async () => {
      try {
        const response = await fetch(`/api/media/poster/${direct.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })

        if (response.status === 200) {
          const blob = await response.blob()

          const posterURL = URL.createObjectURL(blob)
          setPosterSource(posterURL)
        }
      } catch (error) {
        console.error("Error fetching poster :", error)
      }
    }

    direct && fetchPoster()
  }, [direct])

  return direct && posterSource ? (
    <DirectCardMedia image={posterSource || undefined} title={direct.title} className="adaptiveBorderRadius" />
  ) : (
    <img src={DefaultPoster} className="adaptiveBorderRadius" />
  )
}
