import axios, { AxiosInstance, AxiosResponse } from "axios"

// Flemme de faire response.data a chaque fois, donc j'overwrite les methode d'axios
declare module "axios" {
  interface AxiosInstance {
    request<T>(config: AxiosRequestConfig): Promise<T>
    get<T>(url: string, config?: AxiosRequestConfig): Promise<T>
    delete<T>(url: string, config?: AxiosRequestConfig): Promise<T>
    head<T>(url: string, config?: AxiosRequestConfig): Promise<T>
    post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
    put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
    patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
  }
}

class HttpClient {
  readonly instance: AxiosInstance
  public constructor() {
    this.instance = axios.create({
      baseURL: `/api`,
    })

    this._initialize()
  }

  private _initialize = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError
    )
    // this.instance.defaults.headers.common["Accept"] = "application/json";
  }

  private _handleResponse = ({ data }: AxiosResponse) => data

  protected _handleError = (error: any) => {
    console.error(`[HTTPERROR] : ${error}`)
    return Promise.reject(error)
  }
  public setAuthorization(token: string) {
    this.instance.defaults.headers.common["Authorization"] = `Bearer ${token}`
  }
  public deleteAuthorization() {
    delete this.instance.defaults.headers.common["Authorization"]
  }
}

export const httpClient = new HttpClient()

export class APIConsumer {
  prefix: string
  http: AxiosInstance
  public constructor(prefix: string, client: HttpClient) {
    this.prefix = prefix
    this.http = client.instance
  }
}
