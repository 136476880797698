import { HeaderBar } from "../components/HeaderBar"
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  styled,
} from "@mui/material"
import React, { DragEvent, useEffect, useState } from "react"
import { Footer } from "../components/Footer"
import { useDirectAPI } from "../services/api/hooks/useDirectAPI"
import { DirectQueryType, DirectType } from "../services/api/direct"
import { useAuthContext } from "../services/auth"
import { UserRole } from "../services/auth/auth.types"
import { useSnackbarContext } from "../services/snackbar"
import { httpClient } from "../services/http"
import { useHistory, useParams } from "react-router-dom"
import AspectRatioBox from "../components/AspectRatioBox"
import { uploadPoster } from "../utils/upload"
import { DirectPosterImage } from "../components/DirectPoster"
import { ClickAwayListener, Unstable_Popup as Popup } from "@mui/base"
import { Main, PageTitle } from "../components/StyledElements"

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
}))

const FieldContainer = styled(Grid)(({ theme }) => ({
  padding: "20px",
}))

export const EditDirect = (): React.ReactElement => {
  const { directId } = useParams<{ directId: string }>()
  const [posterFile, setPosterFile] = useState<File | null>(null)
  const [title, setTitle] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [date, setDate] = useState<string>(new Date().toISOString().split("T")[0])
  const [promotion, setPromotion] = useState<number | null>(null)
  const [originalDirect, setOriginalDirect] = useState<DirectType | null>(null)
  const [privacy, setPrivacy] = useState<number>(1)
  const [liveUrl, setliveUrl] = useState<string>("")

  const [isUploading, setIsUploading] = useState<boolean>(false)

  const [isDraggingPoster, setIsDraggingPoster] = useState(false)

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false)

  const { role } = useAuthContext()
  const { open: sbOpen } = useSnackbarContext()

  const {
    directsData,
    loading: directLoading,
    error: directError,
  } = useDirectAPI({ constraint: DirectQueryType.DIRECT, id: directId })

  const history = useHistory()

  const handlePosterFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      setPosterFile(file || null)
    }
  }

  const handleDragOverPoster = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingPoster(true)
  }

  const handleDragLeavePoster = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingPoster(false)
  }

  const handleDropPoster = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingPoster(false)
    const files = event.dataTransfer.files
    if (files.length > 0 && (files[0].type === "image/png" || files[0].type === "image/jpeg")) {
      setPosterFile(files[0])
    }
  }

  const handlePrivacyChange = (e: SelectChangeEvent<number>) => {
    setPrivacy(e.target.value as number)
  }

  const handleSubmit = async () => {
    setIsUploading(true)
    const data = {
      title: title,
      description: description,
      privacy: privacy,
      date: date.toString(),
      promotion: (promotion || -1).toString(),
      liveUrl: liveUrl,
    }

    let directResponse: DirectType | null = null

    try {
      directResponse = await httpClient.instance.put(`directs/${directId}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    } catch (error) {
      sbOpen("Erreur pendant la modification du direct", "error")
    } finally {
      if (!directResponse) {
        sbOpen("Erreur pendant la modification du direct", "error")
        setIsUploading(false)
      } else {
        try {
          directResponse && posterFile && uploadPoster(directResponse, posterFile, sbOpen)
          sbOpen("Direct modifié avec succès", "success")
        } catch {
          sbOpen("Erreur pendant l'upload", "error")
        } finally {
          setIsUploading(false)
          history.push(`/direct/${directId}`)
        }
      }
    }
  }

  const handleDelete = async () => {
    let directResponse: DirectType | null = null

    try {
      directResponse = await httpClient.instance.delete(`directs/${directId}`)
    } catch (error) {
      sbOpen("Erreur pendant la suppression du direct", "error")
    } finally {
      if (!directResponse) {
        sbOpen("Erreur pendant la suppression du direct", "error")
      }
      sbOpen("Direct supprimé avec succès", "success")
      history.push("/")
    }
  }

  useEffect(() => {
    if (directLoading) return
    if (directError) {
      sbOpen("Erreur pendant le chargement du direct", "error")
      history.push("/")
      return
    }

    const direct = directsData[0]

    setOriginalDirect(direct)
    setTitle(direct.title)
    setDescription(direct.description)
    setDate(direct.date || "")
    setPromotion(direct.promotion === -1 ? null : direct.promotion || null)
    setPrivacy(direct.privacy)
    setliveUrl(direct.liveUrl)
  }, [directLoading, directError])

  return (
    <React.Fragment>
      <HeaderBar />
      <Main>
        <PageTitle>{"Modifier un direct"}</PageTitle>
        {directLoading && originalDirect ? (
          <Typography color="textPrimary">{"Chargement..."}</Typography>
        ) : (
          <Container>
            <Grid container direction="row">
              <Grid container item md={12} pt={3}>
                <Grid item xs={12} md={6}>
                  <AspectRatioBox ratio={70 / 99}>
                    <DirectPosterImage direct={originalDirect} />
                  </AspectRatioBox>
                </Grid>
                <FieldContainer item xs={12} md={6}>
                  <FormControl fullWidth focused>
                    <Input
                      id="poster-input"
                      type="file"
                      onChange={handlePosterFileInputChange}
                      sx={{ display: "none" }}
                      inputProps={{ style: { display: "none" } }}
                    />
                    <Box
                      sx={{
                        border: "2px solid #424242",
                        borderRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        py: 5,
                        cursor: "pointer",
                        backgroundColor: isDraggingPoster ? "primary.dark" : "inherit",
                      }}
                      onClick={() => document.getElementById("poster-input")?.click()}
                      onDragOver={handleDragOverPoster}
                      onDragLeave={handleDragLeavePoster}
                      onDrop={handleDropPoster}
                    >
                      {posterFile
                        ? posterFile.name
                        : "Pour modifier l'affiche, sélectionnez un fichier .png ou .jpg ici"}
                    </Box>
                  </FormControl>
                </FieldContainer>
              </Grid>
              <Grid item xs={12} md={6} container direction="row">
                <FieldContainer item xs={12}>
                  <StyledTextField
                    label="Titre"
                    variant="standard"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    autoFocus
                    focused
                  />
                </FieldContainer>
                <FieldContainer item xs={12}>
                  <StyledTextField
                    label="Description"
                    variant="outlined"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    multiline
                    minRows={8}
                    maxRows={12}
                    focused
                  />
                </FieldContainer>
                <FieldContainer item xs={12}>
                  <StyledTextField
                    label="Lien du live"
                    variant="standard"
                    value={liveUrl}
                    onChange={(e) => setliveUrl(e.target.value)}
                    autoFocus
                    focused
                  />
                </FieldContainer>
              </Grid>
              <Grid item xs={12} md={6} container direction="row">
                <FieldContainer item xs={6}>
                  <StyledTextField
                    label="Date"
                    variant="standard"
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    focused
                  />
                </FieldContainer>
                <FieldContainer item xs={6}>
                  <FormControl fullWidth variant="standard" focused>
                    <InputLabel htmlFor="promotion-input">Promo</InputLabel>
                    <Input
                      id="promotion-input"
                      type="text"
                      value={promotion || ""}
                      onChange={(e) => setPromotion(e.target.value === "" ? null : parseInt(e.target.value))}
                      placeholder=""
                      startAdornment={<InputAdornment position="start">P</InputAdornment>}
                    />
                  </FormControl>
                </FieldContainer>
                <FieldContainer item xs={4}>
                  <Select
                    value={privacy}
                    variant="standard"
                    onChange={handlePrivacyChange}
                    sx={{ minWidth: 120 }}
                    disabled={role !== UserRole.GEEK && privacy === 0}
                  >
                    {(role === UserRole.GEEK || privacy === 0) && <MenuItem value={0}>{"Publique"}</MenuItem>}
                    <MenuItem value={1}>{"CS"}</MenuItem>
                    <MenuItem value={2}>{"Hyris"}</MenuItem>
                    {role === UserRole.GEEK && <MenuItem value={3}>{"Archivé"}</MenuItem>}
                  </Select>
                  <FormHelperText>{"Accès"}</FormHelperText>
                </FieldContainer>
                <FieldContainer item xs={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                    disabled={title === ""}
                    sx={{ margin: 1 }}
                  >
                    {"Valider"}
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ margin: 1 }}
                    onClick={() => setDeletePopupOpen(true)}
                    id="delete-button"
                  >
                    {"Supprimer"}
                  </Button>
                  <Popup
                    anchor={document.querySelector("#delete-button")}
                    open={isDeletePopupOpen}
                    placement="top"
                    offset={10}
                  >
                    <ClickAwayListener onClickAway={() => setDeletePopupOpen(false)}>
                      <Box padding={2} borderRadius="5px" sx={{ backgroundColor: "primary.main" }}>
                        <Typography>{"Etes-vous sûr de vouloir supprimer ce DT ?"}</Typography>
                        <Button
                          variant="contained"
                          color="secondary"
                          sx={{ margin: 1 }}
                          onClick={() => setDeletePopupOpen(false)}
                        >
                          {"Annuler"}
                        </Button>
                        <Button variant="outlined" color="error" onClick={handleDelete} sx={{ margin: 1 }}>
                          {"Oui, supprimer"}
                        </Button>
                      </Box>
                    </ClickAwayListener>
                  </Popup>
                </FieldContainer>
              </Grid>
            </Grid>
          </Container>
        )}
      </Main>
      <Footer />

      <Backdrop sx={{ color: "#fff", zIndex: 300, display: "flex", flexDirection: "column" }} open={isUploading}>
        <CircularProgress color="inherit" />
        <Typography variant="h6" sx={{ mt: 2 }}>
          {"Upload en cours, veuillez ne pas quitter cette page..."}
        </Typography>
      </Backdrop>
    </React.Fragment>
  )
}
