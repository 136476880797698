import { useState, useEffect } from "react"
import axios from "axios"
import { TagQuery, TagType, getTags } from "../tag"

export interface IFetchTag {
  tagsData: TagType[]
  loading: boolean
  error: string | null
}

export const useTagAPI = (query: TagQuery): IFetchTag => {
  const [tagsData, setTags] = useState<TagType[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const fetchTags = async (queryParam: TagQuery) => {
    setLoading(true)
    try {
      const tagData = await getTags(queryParam)
      setTags(tagData)
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          setError("Déconnexion : Veuillez vous reconnecter")
        } else {
          setError(`Erreur ${err.response?.status}`)
        }
      } else {
        setError("Type d'erreur non catch")
      }
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchTags(query)
  }, [])
  return { tagsData, loading, error }
}
