import { Typography } from "@mui/material"
import { Footer } from "../components/Footer"
import { HeaderBar } from "../components/HeaderBar"
import React from "react"
import { Main } from "../components/StyledElements"

export const NotImplemented = (): React.ReactElement => {
  return (
    <React.Fragment>
      <HeaderBar />
      <Main>
        <Typography component="h2" variant="h3" color="textPrimary" align="center" gutterBottom>
          {"Cette page est en cours de construction..."}
        </Typography>
      </Main>
      <Footer />
    </React.Fragment>
  )
}
