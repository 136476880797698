import { VideoType } from "../services/api/video"
import { Box, CardMedia, styled, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { FormatDate, StylelessLink } from "./Util"
import DefaultThumbnail from "../assets/default-thumbnail.jpg"
import AspectRatioBox from "./AspectRatioBox"
import { useAuthContext } from "../services/auth"
import { HoverOverlay, Overlay } from "./StyledElements"

const VideoCardMedia = styled(CardMedia)({
  paddingTop: "56.25%",
})

interface VideoThumbnailProps {
  video: VideoType
}

export const VideoThumbnail = ({ video }: VideoThumbnailProps): React.ReactElement => {
  const { isAuth } = useAuthContext()
  return (
    <Box mb={2}>
      <StylelessLink to={"/video/" + video.id}>
        <HoverOverlay sx={{ display: "flex", flexDirection: "column", height: "fit-content" }}>
          <AspectRatioBox ratio={16 / 9}>
            <VideoThumbnailImage video={video} />
            <Overlay className="overlay" />
          </AspectRatioBox>
          <Box mt={1} sx={{ position: "relative", zIndex: 2 }}>
            <Typography marginLeft={1} noWrap variant="h5" fontSize={"1.2rem"} textOverflow="ellipsis">
              {video.title}
            </Typography>
            <Typography
              marginLeft={1}
              noWrap
              variant="subtitle1"
              fontSize={".8rem"}
              color={"gray"}
              textOverflow="ellipsis"
            >
              {FormatDate(video.date) +
                " - " +
                video.views +
                " vue" +
                (video.views > 1 ? "s" : "") +
                (video.likeCount && isAuth()
                  ? " - " + video.likeCount + " like" + (video.likeCount > 1 ? "s" : "")
                  : "")}
            </Typography>
          </Box>
        </HoverOverlay>
      </StylelessLink>
    </Box>
  )
}

interface VideoThumbnailImageProps {
  video: VideoType | null
}

export const VideoThumbnailImage = ({ video }: VideoThumbnailImageProps): React.ReactElement => {
  const [thumbnailSource, setThumbnailSource] = useState<string | null>(null)

  useEffect(() => {
    if (!video) return
    const fetchThumbnail = async () => {
      try {
        const response = await fetch(`/api/media/thumbnail/${video.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })

        if (response.status === 200) {
          const blob = await response.blob()
          const thumbnailURL = URL.createObjectURL(blob)
          setThumbnailSource(thumbnailURL)
        }
      } catch (error) {
        console.error("Error fetching thumbnail:", error)
      }
    }

    fetchThumbnail()
  }, [video])

  return video && thumbnailSource ? (
    <VideoCardMedia image={thumbnailSource || undefined} title={video.title} className="adaptiveBorderRadius" />
  ) : (
    <img
      src={DefaultThumbnail}
      style={{ width: "100%", height: "100%", objectFit: "cover" }}
      className="adaptiveBorderRadius"
    />
  )
}
