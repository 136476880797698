import { Box, Button, Container, Grid, Typography } from "@mui/material"
import { Footer } from "../components/Footer"
import { HeaderBar } from "../components/HeaderBar"
import React, { useEffect, useState } from "react"
import { useDirectAPI } from "../services/api/hooks/useDirectAPI"
import { DirectQueryType } from "../services/api/direct"
import { useSnackbarContext } from "../services/snackbar"
import { DirectPoster } from "../components/DirectPoster"
import { Main, PageTitle } from "../components/StyledElements"

export const Directs = (): React.ReactElement => {
  const pageSize = 32
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const { directsData, error, refresh, nResults } = useDirectAPI({
    constraint: DirectQueryType.LATEST,
    page: page,
    pageSize: pageSize,
  })
  const { open: sbOpen } = useSnackbarContext()

  useEffect(() => {
    refresh()
  }, [page, pageSize])
  useEffect(() => {
    if (error) sbOpen("Une erreur s'est produite", "error")
  }, [error])
  useEffect(() => {
    if (nResults) setMaxPage(Math.max(0, Math.floor((nResults - 1) / pageSize) + 1))
  }, [nResults])

  const pageControls =
    maxPage > 1 ? (
      <Box display="flex" alignItems="center" gap={2} pt={2}>
        <Button onClick={() => setPage((prev) => Math.max(prev - 1, 1))} disabled={page === 1} variant="contained">
          {"←"}
        </Button>

        <Typography variant="h5" fontSize={"1.2rem"}>{`Page ${page} / ${maxPage}`}</Typography>

        <Button
          onClick={() => setPage((prev) => Math.min(prev + 1, maxPage))}
          disabled={page >= maxPage}
          variant="contained"
        >
          {"→"}
        </Button>
      </Box>
    ) : (
      <></>
    )

  return (
    <React.Fragment>
      <HeaderBar />
      <Main>
        <PageTitle>{"Tous nos DT"}</PageTitle>
        <Box padding={2}>
          {pageControls}
          {directsData ? (
            <Container
              sx={{
                py: 8,
              }}
            >
              <Grid container spacing={4}>
                {directsData.map((direct) => (
                  <Grid item key={direct.id} xs={6} sm={4} md={3}>
                    <DirectPoster direct={direct} />
                  </Grid>
                ))}
              </Grid>
            </Container>
          ) : (
            <h1>{"Chargement des DT..."}</h1>
          )}
          {pageControls}
        </Box>
      </Main>
      <Footer />
    </React.Fragment>
  )
}
