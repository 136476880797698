import React from "react"
import { Typography, Link } from "../../node_modules/@mui/material"

export const Copyright = (): React.ReactElement => {
  return (
    <Typography variant="body2" color="textPrimary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://hyris.tv">
        hyris.tv
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}
