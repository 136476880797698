import React, { useEffect, useState } from "react"
import { Box, Button, CardMedia, Container, Grid, Hidden, Typography, useTheme } from "@mui/material"
import { HeaderBar } from "../components/HeaderBar"
import { useAuthContext } from "../services/auth"
import { PagedVideoSuggestions } from "../components/VideoSuggestions"
import { Footer } from "../components/Footer"
import { ReactComponent as HyrisLogo } from "../assets/svg/hyris-full.svg"
import { useHistory } from "react-router-dom"
import { VideoThumbnail } from "../components/VideoThumbnail"
import { useVideoAPI } from "../services/api/hooks/useVideoAPI"
import { VideoQueryType } from "../services/api/video"
import { DirectCardMedia, DirectPoster, DirectPosterImage } from "../components/DirectPoster"
import { useDirectAPI } from "../services/api/hooks/useDirectAPI"
import { DirectQueryType, DirectType, PagedDirectResponse } from "../services/api/direct"
import { FormatDate, FormatParagraphs, StylelessLink } from "../components/Util"
import { Main, PageTitle } from "../components/StyledElements"
import { httpClient } from "../services/http"
import axios from "axios"

import { ReactComponent as DownIcon } from "../assets/svg/down.svg"
import { ReactComponent as PlayIcon } from "../assets/svg/play.svg"

const splashTextPool = [
  "Bienvenue sur hyris.tv !",
  "25 images par seconde !",
  "Bonsoir CentraleSupélec !",
  "Allez aussi voir galerie.pics !",
  "Allez aussi voir viarezo.fr !",
  "Allez aussi voir sbcs-events.fr !",
  "Site 2.0 !",
  "Poooolop !",
  "Vidéastes et régisseurs !",
  "= NX + SMS !",
  "Depuis 1990 !",
  "100% d'étudiants !",
  "Vidéos faites maison !",
  "P26 !",
  "Hello, world !",
  "Hyris, c'est bien !",
  "Existe aussi en mode jour !",
  "1080p !",
  "10 337 lignes de code !",
  "Plenty more to come !",
]

export const Home = (): React.ReactElement => {
  const { isAuth } = useAuthContext()
  return isAuth() ? <PrivateHome /> : <PublicHome />
}

interface HomeDisplayResponse {
  homeDisplay: number
}

const PrivateHome = (): React.ReactElement => {
  const [currentDirect, setCurrentDirect] = useState<DirectType | null>(null)
  const [homeDisplay, setHomeDisplay] = useState<number>(0)
  const [posterSource, setPosterSource] = useState<string | null>(null)
  const theme = useTheme()
  const history = useHistory()
  const {
    videosData: trending,
    loading: trendingLoading,
    error: trendingError,
  } = useVideoAPI({ constraint: VideoQueryType.TRENDING })
  const {
    videosData: latest,
    loading: latestLoading,
    error: latestError,
  } = useVideoAPI({ constraint: VideoQueryType.LATEST })
  const {
    directsData: directs,
    loading: directsLoading,
    error: directsError,
  } = useDirectAPI({ constraint: DirectQueryType.LATEST })

  useEffect(() => {
    if (trendingError) {
      console.error("Couldn't fetch trending videos", "error")
    }
    if (latestError) {
      console.error("Couldn't fetch last videos", "error")
    }
    if (directsError) {
      console.error("Couldn't fetch last directs", "error")
    }
  }, [trendingError, latestError, directsError])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { homeDisplay: hd } = await httpClient.instance.get<HomeDisplayResponse>(`homeDisplay`)
        setHomeDisplay(hd)
        if (hd) {
          const response = await httpClient.instance.get<PagedDirectResponse>(`currentDirect`)
          setCurrentDirect((response.directs && response.directs[0]) || null)
        }
      } catch (error) {
        console.log("homeDisplayError Home")
        if (axios.isAxiosError(error) && error.status === 401) {
          // Osef
        } else {
          console.error("Unexpected error fetching home display or direct type:", error)
        }
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchPoster = async () => {
      if (!(homeDisplay && currentDirect)) return
      try {
        const response = await fetch(`/api/media/poster/${currentDirect.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })

        const blob = await response.blob()

        const posterURL = URL.createObjectURL(blob)
        setPosterSource(posterURL)
      } catch (error) {
        console.error("Error fetching poster :", error)
      }
    }

    fetchPoster()
  }, [currentDirect])

  return (
    <React.Fragment>
      <HeaderBar />
      <Main>
        <Box
          sx={{
            pt: 0,
            pb: 8,
            px: 0,
          }}
        >
          {homeDisplay && currentDirect && posterSource && !trendingLoading && !latestLoading && !directsLoading ? (
            <Box
              sx={{
                position: "absolute",
                top: "0",
                overflow: "hidden",
                width: "100vw",
                minHeight: "100vh",
                maxHeight: "200vh",
              }}
            >
              <CardMedia
                component="img"
                image={posterSource}
                sx={{
                  left: "50%",
                  width: "100%",
                  objectFit: "cover",
                  zIndex: 100,
                  transform: "translateY(min(80px,calc(300px - 30vw)))",
                  opacity: 0.6,
                  filter: "blur(.5vw)",
                  maskImage: "linear-gradient(to bottom, rgba(0,0,0,1) 20%, rgba(0,0,0,0) min(100%, 200vh))",
                }}
              />
            </Box>
          ) : (
            <></>
          )}
          <Box sx={{ position: "relative", zIndex: 200 }}>
            <Box pt={3}>
              <PageTitle>{"Bienvenue sur hyris.tv !"}</PageTitle>
            </Box>
            {(homeDisplay === 1 || homeDisplay === 2) && currentDirect ? (
              <Box borderBottom="1px solid darkgray" sx={{ py: 5, px: 3 }}>
                <Grid container spacing={3} direction="row" justifyContent={"center"}>
                  {posterSource ? (
                    <Hidden smDown>
                      <Grid item md={6} lg={4}>
                        <DirectCardMedia
                          image={posterSource}
                          title={currentDirect.title}
                          className="adaptiveBorderRadius"
                        />
                      </Grid>
                    </Hidden>
                  ) : (
                    <></>
                  )}
                  <Grid item sm={12} md={6}>
                    <Typography component="h3" variant="h3" fontSize={"2rem"} sx={{ userSelect: "none" }}>
                      {homeDisplay === 1 ? "Prochain DT" : "DT en cours"}
                    </Typography>
                    <Typography component="h3" variant="h3" fontSize={"1.5rem"} fontWeight={600}>
                      {currentDirect.title}
                    </Typography>
                    {currentDirect.date && (
                      <Typography variant="subtitle1" fontSize={".8rem"}>
                        {FormatDate(currentDirect.date)}
                      </Typography>
                    )}
                    {currentDirect.liveUrl && homeDisplay === 2 && (
                      <Box
                        onClick={() => history.push("/dt")}
                        width="fit-content"
                        bgcolor="red"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignContent: "center",
                          justifyContent: "flex-start",
                        }}
                        sx={{
                          padding: 1,
                          paddingRight: 2,
                          flexShrink: 0,
                          textAlign: "left",
                          transition: ".3s",
                          cursor: "pointer",
                          borderRadius: "5px",
                          my: 4,
                          "&:hover": {
                            backgroundColor: theme.palette.mode === "light" ? "#ff3333" : "darkred",
                          },
                        }}
                      >
                        <PlayIcon height="30px" width="30px" style={{ fill: "white", marginRight: "5px" }} />
                        <Typography fontWeight="bold" lineHeight={1.8} color="white">
                          {"LIVE"}
                        </Typography>
                      </Box>
                    )}
                    <Typography mt={4}>
                      <FormatParagraphs content={currentDirect.description} />
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <></>
            )}
            {trendingLoading ? (
              <></>
            ) : (
              <Box borderBottom="1px solid darkgray" sx={{ py: 5, px: 3 }}>
                <Box>
                  <Typography component="h3" variant="h3" fontSize={"2rem"} sx={{ userSelect: "none" }} mb={2}>
                    {"A la une"}
                  </Typography>
                  <Grid container spacing={3} direction="row">
                    {trending[0] && (
                      <Grid item xs={12} sm={12} md={6}>
                        <VideoThumbnail video={trending[0]} />
                      </Grid>
                    )}
                    <Grid item container spacing={3} xs={12} sm={12} md={6} direction="row">
                      {trending[1] && (
                        <Grid item xs={12} sm={6} md={6}>
                          <VideoThumbnail video={trending[1]} />
                        </Grid>
                      )}
                      {trending[2] && (
                        <Grid item xs={12} sm={6} md={6}>
                          <VideoThumbnail video={trending[2]} />
                        </Grid>
                      )}
                      {trending[3] && (
                        <Grid item xs={12} sm={6} md={6}>
                          <VideoThumbnail video={trending[3]} />
                        </Grid>
                      )}
                      {trending[4] && (
                        <Grid item xs={12} sm={6} md={6}>
                          <VideoThumbnail video={trending[4]} />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
            {latestLoading ? (
              <></>
            ) : (
              <Box borderBottom="1px solid darkgray" sx={{ py: 5, px: 3 }}>
                <Box>
                  <Typography component="h3" variant="h3" fontSize={"2rem"}>
                    <StylelessLink to="/videos">{"Nos dernières vidéos"}</StylelessLink>
                  </Typography>
                  <Grid container spacing={1} direction="row" sx={{ py: 2 }}>
                    {latest.slice(0, 6).map((video) => (
                      <Grid key={video.id} item xs={12} sm={4} md={2}>
                        <VideoThumbnail video={video} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            )}
            {directsLoading ? (
              <></>
            ) : (
              <Box sx={{ py: 5, px: 3 }}>
                <Box>
                  <Typography component="h3" variant="h3" fontSize={"2rem"}>
                    <StylelessLink to="/directs">{"Nos derniers DT"}</StylelessLink>
                  </Typography>
                  <Grid container spacing={1} direction="row" sx={{ py: 2 }}>
                    {directs.slice(0, 6).map((direct) => (
                      <Grid key={direct.id} item xs={6} sm={4} md={2}>
                        <DirectPoster direct={direct} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Main>
      <Box sx={{ position: "relative", zIndex: "300" }}>
        <Footer />
      </Box>
    </React.Fragment>
  )
}

const PublicHome = (): React.ReactElement => {
  const { isAuth, logout } = useAuthContext()
  const history = useHistory()
  const [splashText] = useState<string>(
    splashTextPool[Math.floor(Math.max((Math.random() * 10 - 9) * splashTextPool.length, 0))]
  )

  const handleLoginClick = () => {
    if (isAuth()) {
      logout()
    }
    history.push("/login")
  }

  return (
    <React.Fragment>
      <HeaderBar noSearchBar />
      <Main>
        <Box
          sx={{
            pt: 8,
            pb: 6,
            px: 0,
            position: "relative",
            width: "100vw",
            height: "90vh",
            overflow: "hidden",
          }}
        >
          <video
            autoPlay
            loop
            muted
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              minWidth: "100%",
              minHeight: "100%",
              width: "auto",
              height: "auto",
              zIndex: 100,
              transform: "translateX(-50%) translateY(-50%)",
            }}
          >
            <source src="/video-banner.mp4" type="video/mp4" />
            <source src="/video-banner.mp4" type="video/webm" />
          </video>
          <Container maxWidth="md" sx={{ mb: 8, position: "relative", zIndex: 200 }}>
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
              <Grid item md={12} display="flex" justifyContent="center">
                <HyrisLogo
                  fill="white"
                  style={{ maxWidth: "60%", maxHeight: "40vh", width: "800px", height: "auto" }}
                />
              </Grid>
              <Box
                sx={{
                  my: 1,
                  width: "100%",
                }}
              >
                <Typography
                  component="h1"
                  variant="h2"
                  align="center"
                  color="common.white"
                  gutterBottom
                  sx={{ textShadow: "1px 1px 2px black", userSelect: "none" }}
                >
                  {splashText}
                </Typography>
              </Box>
              <Grid container spacing={3} justifyContent="center">
                <Grid item>
                  <Button variant="contained" color="secondary" size="large" onClick={handleLoginClick}>
                    {"Connexion"}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="large"
                    onClick={() => history.push("/contact")}
                    sx={{
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                      },
                    }}
                  >
                    {"Contact"}
                  </Button>
                </Grid>
              </Grid>
              <Box mt={8} display="flex" justifyContent="center" width="100px" maxWidth="100%">
                <DownIcon
                  fill="white"
                  height={"50px"}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.scrollTo({ top: window.innerHeight - 80, behavior: "smooth" })
                  }}
                />
              </Box>
            </Grid>
          </Container>
        </Box>
        <Box px={4} py={8}>
          <Typography component="h3" variant="h3" fontSize={"2rem"}>
            {"Nos vidéos publiques"}
          </Typography>
          <PagedVideoSuggestions
            constraint={VideoQueryType.LATEST}
            tagFilter={[]}
            md={3}
            pageSize={48}
            hideTopControls
          />
        </Box>
      </Main>
      <Box sx={{ position: "relative", zIndex: "300" }}>
        <Footer />
      </Box>
    </React.Fragment>
  )
}
