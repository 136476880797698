import { useVideoAPI } from "../services/api/hooks/useVideoAPI"
import { VideoQuery, VideoQueryType, VideoType } from "../services/api/video"
import { useSnackbarContext } from "../services/snackbar"
import { Box, Button, Grid, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { VideoThumbnail } from "./VideoThumbnail"
import { TagType } from "../services/api/tag"

interface VideoSuggestionsProps {
  query: VideoQuery
  xs?: number
  sm?: number
  md?: number
}

export const VideoSuggestions = ({ query, xs = 12, sm = 6, md = 2 }: VideoSuggestionsProps): React.ReactElement => {
  const { videosData, error, refresh } = useVideoAPI(query)
  const { open: sbOpen } = useSnackbarContext()
  useEffect(() => {
    refresh()
  }, [query.constraint, query.id, query.page, query.pageSize, query.tag_filter, query.search, query.exclude])
  useEffect(() => {
    if (error) sbOpen("Une erreur s'est produite", "error")
  }, [error])
  return videosData ? <VideoList videos={videosData} xs={xs} sm={sm} md={md} /> : <h1>{"Chargement des vidéos..."}</h1>
}

interface PagedVideoSuggestionsProps {
  constraint?: VideoQueryType
  tagFilter?: TagType[]
  search?: string
  xs?: number
  sm?: number
  md?: number
  pageSize?: number
  hideTopControls?: boolean
}

export const PagedVideoSuggestions = ({
  constraint,
  tagFilter = [],
  search = "",
  xs = 12,
  sm = 6,
  md = 2,
  pageSize = 48,
  hideTopControls = false,
}: PagedVideoSuggestionsProps): React.ReactElement => {
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const { videosData, error, refresh, nResults } = useVideoAPI({
    constraint: constraint,
    tag_filter: tagFilter,
    search: search,
    page: page,
    pageSize: pageSize,
  })
  const { open: sbOpen } = useSnackbarContext()

  useEffect(() => {
    refresh()
  }, [constraint, tagFilter, page, pageSize])
  useEffect(() => {
    if (error) sbOpen("Une erreur s'est produite", "error")
  }, [error])
  useEffect(() => {
    if (nResults) setMaxPage(Math.max(0, Math.floor((nResults - 1) / pageSize) + 1))
  }, [nResults])

  const pageControls =
    maxPage > 1 ? (
      <Box display="flex" alignItems="center" gap={2} pt={2}>
        <Button onClick={() => setPage((prev) => Math.max(prev - 1, 1))} disabled={page === 1} variant="contained">
          {"←"}
        </Button>

        <Typography variant="h5" fontSize={"1.2rem"}>{`Page ${page} / ${maxPage}`}</Typography>

        <Button
          onClick={() => setPage((prev) => Math.min(prev + 1, maxPage))}
          disabled={page >= maxPage}
          variant="contained"
        >
          {"→"}
        </Button>
      </Box>
    ) : (
      <></>
    )

  return videosData ? (
    <>
      {hideTopControls ? <></> : pageControls}
      <VideoList videos={videosData} xs={xs} sm={sm} md={md} />
      {pageControls}
    </>
  ) : (
    <h1>{"Chargement des vidéos..."}</h1>
  )
}

interface VideoListProps {
  videos: VideoType[]
  xs?: number
  sm?: number
  md?: number
}

export const VideoList = ({ videos, xs, sm, md }: VideoListProps): React.ReactElement => {
  return (
    <Grid container spacing={2} py={8}>
      {videos.map((video) => (
        <Grid item key={video.id} xs={xs || 12} sm={sm || 6} md={md || 4}>
          <VideoThumbnail video={video} />
        </Grid>
      ))}
    </Grid>
  )
}
