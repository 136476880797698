import { createContext } from "react"
import { Credentials, UserRole } from "./auth.types"

export interface AuthContextType {
  login: (creds: Credentials) => Promise<void>
  VRlogin: (params: string) => Promise<void>
  logout: () => void
  isAuth: () => boolean
  role: UserRole | null
  loading: boolean
  username: string | null
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined)
