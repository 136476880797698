import React, { createContext, useState, useMemo, useContext, useEffect } from "react"
import { ThemeProvider } from "@mui/material/styles"
import { CssBaseline } from "@mui/material"
import { defaultTheme, lightTheme } from "./theme"

interface ThemeContextProps {
  toggleTheme: () => void
  setTheme: (mode: number) => void
  mode: number
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined)

const themePool = [defaultTheme, lightTheme, defaultTheme]

export const ThemeProviderWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const storedTheme = Number(localStorage.getItem("themeMode"))
  const initialMode = isNaN(storedTheme) ? 0 : storedTheme

  const [mode, setMode] = useState(initialMode)

  const toggleTheme = () => {
    setMode((prev) => {
      const newMode = prev ? 0 : 1
      localStorage.setItem("themeMode", String(newMode))
      return newMode
    })
  }

  const setTheme = (newMode: number) => {
    setMode(() => {
      localStorage.setItem("themeMode", String(newMode))
      return newMode
    })
  }

  const theme = useMemo(() => themePool[mode], [mode])

  return (
    <ThemeContext.Provider value={{ toggleTheme, setTheme, mode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

export const useThemeContext = () => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error("useThemeContext must be used within a ThemeProviderWrapper")
  }
  return context
}
