export interface Credentials {
  username: string
  password: string
}
export enum UserRole {
  ALL = "all",
  USER = "user",
  HYRIS = "hyris",
  GEEK = "geek", // ADMIN
}

export type TokenType = { accessToken: string }

export interface TokenData {
  role: UserRole
  user: string
  exp: number
  username: string
}
