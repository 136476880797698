import { Box, Container, InputBase, Typography, styled, useTheme } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import React, { FormEvent, useState } from "react"
import { useVideoAPI } from "../services/api/hooks/useVideoAPI"
import { VideoQueryType } from "../services/api/video"
import { useDirectAPI } from "../services/api/hooks/useDirectAPI"
import { DirectQueryType } from "../services/api/direct"
import { StylelessLink, delay, formatSearch } from "./Util"
import { useHistory } from "react-router-dom"

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.element.searchBar.background,
  "&:hover": {
    backgroundColor: theme.palette.element.searchBar.backgroundHover,
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}))

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}))

interface SearchBarProps {
  setSearchFocus?: (focused: boolean) => void
}

export const SearchBar = ({ setSearchFocus }: SearchBarProps): React.ReactElement => {
  const [search, setSearch] = useState<string>("")
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const history = useHistory()
  const { videosData: videoResults, loading: loadingVideoResults } = useVideoAPI({
    constraint: VideoQueryType.SEARCH,
    search: search,
  })
  const { directsData: directResults, loading: loadingDirectsResults } = useDirectAPI({
    constraint: DirectQueryType.SEARCH,
    search: search,
  })
  const theme = useTheme()

  const handleFocus = () => {
    setIsFocused(true)
    setSearchFocus?.(true)
  }

  const handleBlur = async () => {
    await delay(100)
    setIsFocused(false)
    setSearchFocus?.(false)
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    if (search.trim()) {
      const newPath = `/search/${formatSearch(search)}`

      if (window.location.pathname.startsWith("/search/")) {
        history.replace(newPath)
      } else {
        history.push(newPath)
      }
    }
  }

  return (
    <Container maxWidth="md">
      <form onSubmit={handleSubmit}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon fill={theme.palette.element.searchBar.icon} />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Rechercher…"
            inputProps={{ "aria-label": "search" }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Search>
      </form>
      {isFocused && (
        <Box
          sx={{
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
            width: "50%",
            minWidth: "300px",
          }}
        >
          {!loadingVideoResults && videoResults.length > 0 && (
            <>
              <Typography
                sx={{
                  padding: 1,
                  backgroundColor: "primary.main",
                }}
                fontWeight="bold"
                textAlign="start"
              >
                {"Vidéos :"}
              </Typography>
              <Box>
                {videoResults.slice(0, 10).map((video) => (
                  <StylelessLink key={video.id} to={`/video/${video.id}`}>
                    <Box
                      sx={{
                        backgroundColor: "primary.main",
                        "&:hover": { backgroundColor: "primary.light" },
                        transition: ".2s",
                      }}
                    >
                      <Typography sx={{ padding: 1, pl: 2 }} textAlign="start" textOverflow="ellipsis" noWrap>
                        {video.title}
                      </Typography>
                    </Box>
                  </StylelessLink>
                ))}
              </Box>
            </>
          )}
          {!loadingDirectsResults && directResults.length > 0 && (
            <>
              <Typography sx={{ padding: 1, backgroundColor: "primary.main" }} fontWeight="bold" textAlign="start">
                {"Directs :"}
              </Typography>
              <Box>
                {directResults.slice(0, 5).map((direct) => (
                  <StylelessLink key={direct.id} to={`/direct/${direct.id}`}>
                    <Box
                      sx={{
                        backgroundColor: "primary.main",
                        "&:hover": { backgroundColor: "primary.light" },
                        transition: ".2s",
                      }}
                    >
                      <Typography sx={{ padding: 1, pl: 2 }} textAlign="start" textOverflow="ellipsis" noWrap>
                        {direct.title}
                      </Typography>
                    </Box>
                  </StylelessLink>
                ))}
              </Box>
            </>
          )}
        </Box>
      )}
    </Container>
  )
}
