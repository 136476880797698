import { httpClient } from "../http"

export interface TagType {
  id: number
  name: string
}

export interface TagQuery {
  id?: number
}

export const getTags = async (query: TagQuery): Promise<TagType[]> => {
  let param = "tags"
  if (query.id) {
    param += `/${query.id}`
  }

  return httpClient.instance.get<TagType[]>(param)
}
