import React, { useState, ReactNode, useEffect } from "react"
import decodeJwt from "jwt-decode"
import { AuthContext, AuthContextType } from "./authContext"

import { httpClient } from "../http"
import { LocalRole, LocalToken } from "../../utils/localStorage"
import { UserRole, TokenType, TokenData } from "./auth.types"
import axios from "axios"

const wrongCredentialsMessagePool = [
  "Identifiants incorrects",
  "{wrongCredentialsErrorMessage}",
  "On se connaît ? Non, j'ai dû confondre.",
  "You shall not pass !!",
  "Ca m'arrive aussi d'oublier des mots de passe...",
  "On bougera pas tant que les identifiants sont pas bons.",
  "Une erreur attendue s'est produite...",
  "Je deviennes foux.",
]

export const AuthProvider: React.FC<ReactNode> = ({ children }) => {
  const [userId, setUserId] = useState<string | null>(null)
  const [username, setUsername] = useState<string | null>(null)
  const [role, setRole] = useState<UserRole | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (userId) return
    setLoading(true)
    const token = LocalToken.get()
    if (token) {
      const decoded: TokenData = decodeJwt(token)
      setRole(decoded.role)
      setUserId(decoded.user)
      httpClient.setAuthorization(token)
    }
    setLoading(false)
  }, [userId])

  const logout = () => {
    LocalToken.reset()
    LocalRole.reset()
    setUserId(null)
    setRole(null)
    httpClient.deleteAuthorization()
  }

  const contextValue: AuthContextType = {
    loading,
    role,

    login: async ({ username, password }) => {
      setLoading(true)
      if (!(username.length > 0) || !(password.length > 0)) {
        throw new Error("Veuillez renseinger un identifiant et un mot de passe")
      }
      const formData = new FormData()
      formData.append("username", username)
      formData.append("password", password)
      try {
        const { accessToken: token }: TokenType = await httpClient.instance.request<TokenType>({
          url: "token",
          method: "POST",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        const decoded: TokenData = decodeJwt(token)
        setUserId(decoded.user)
        setRole(decoded.role)
        setUsername(decoded.username)
        LocalToken.set(token)
        httpClient.setAuthorization(token)
      } catch (err: unknown) {
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 401) {
            throw new Error(
              wrongCredentialsMessagePool[
                Math.floor(Math.max((Math.random() * 10 - 9) * wrongCredentialsMessagePool.length, 0))
              ]
            )
          }
        }
        throw new Error("Une erreur s'est produite...")
      } finally {
        setLoading(false)
      }
    },

    VRlogin: async (params: string) => {
      setLoading(true)
      try {
        const { accessToken: token }: TokenType = await httpClient.instance.request<TokenType>({
          url: `auth/callback${params}`,
          method: "GET",
          withCredentials: true,
        })
        const decoded: TokenData = decodeJwt(token)
        setUserId(decoded.user)
        setRole(decoded.role)
        setUsername(decoded.username)
        LocalToken.set(token)
        httpClient.setAuthorization(token)
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 401) {
            throw new Error(
              wrongCredentialsMessagePool[
                Math.floor(Math.max((Math.random() * 10 - 9) * wrongCredentialsMessagePool.length, 0))
              ]
            )
          }
        }
        throw new Error("Une erreur s'est produite...")
      } finally {
        setLoading(false)
      }
    },

    logout,
    isAuth: () => {
      const token = LocalToken.get()
      if (!token) return false
      const { exp } = decodeJwt(token) as TokenData

      // If the token is expired
      if (Date.now() >= exp * 1000) {
        logout()
        return false
      }

      return userId !== null
    },
    username: username,
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}
