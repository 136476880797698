import mojs from "@mojs/core"

class HyrisShape extends mojs.CustomShape {
  getShape() {
    return '<path d="M64.653 49.899c.034 3.718-.653 7.318-1.833 10.83-1.32 3.936-3.267 7.554-5.61 10.967-2.408 3.51-5.198 6.696-8.23 9.676-3.86 3.798-8.065 7.179-12.505 10.27a116.253 116.253 0 01-12.56 7.616 4.63 4.63 0 01-.543.25.343.343 0 01-.419-.132.363.363 0 01.064-.442 5.033 5.033 0 01.484-.352c1.962-1.31 3.942-2.594 5.88-3.933 4.766-3.28 9.358-6.787 13.678-10.643 3.572-3.19 6.92-6.595 9.915-10.34 2.545-3.182 4.779-6.563 6.534-10.248a36.834 36.834 0 003.09-9.558 32.53 32.53 0 00.365-8.371 35.304 35.304 0 00-1.317-7.007q-.135-.459-.258-.923a1.774 1.774 0 01-.058-.353.278.278 0 01.179-.285.31.31 0 01.385.063 2.032 2.032 0 01.262.466 30.235 30.235 0 011.196 3.393 34.214 34.214 0 011.3 7.555c.028.5 0 1 0 1.5zm-29.341.166a32.047 32.047 0 01.875-7.312c1.041-4.512 2.916-8.667 5.368-12.574 2.264-3.606 4.933-6.891 7.882-9.955 3.747-3.886 7.847-7.358 12.213-10.529A116.104 116.104 0 0176.064.756a5.46 5.46 0 01.6-.27.307.307 0 01.369.125.352.352 0 01-.037.443 3.378 3.378 0 01-.429.323c-1.417.938-2.843 1.863-4.253 2.812-4.747 3.2-9.338 6.609-13.689 10.333-3.713 3.18-7.209 6.579-10.368 10.316-2.305 2.723-4.403 5.595-6.172 8.699a41.008 41.008 0 00-3.83 8.904 33.173 33.173 0 00-1.26 11.51 33.655 33.655 0 001.238 7.14c.116.402.23.806.338 1.211a2.741 2.741 0 01.087.47.287.287 0 01-.208.324.323.323 0 01-.376-.11 2.536 2.536 0 01-.25-.475c-.284-.75-.583-1.49-.833-2.252a33.222 33.222 0 01-1.554-7.563c-.083-.87-.088-1.752-.125-2.63z M50.231 44.913a5.152 5.152 0 014.906 3.403c1.038 2.836-.46 6.276-3.717 7.132-3.067.81-6.1-1.627-5.95-4.793a2.512 2.512 0 01.063-.475.375.375 0 01.353-.275.332.332 0 01.31.243c.055.191.09.389.126.584.275 1.31.964 2.308 2.19 2.901a3.128 3.128 0 002.67.077c2.182-.9 3.128-3.332 2.103-5.421-.578-1.174-1.542-1.86-2.81-2.127a4.504 4.504 0 00-2.137.08 4.763 4.763 0 01-.524.125.353.353 0 01-.375-.239.332.332 0 01.105-.375 1.658 1.658 0 01.298-.197 4.735 4.735 0 012.39-.643z" />'
  }
}
mojs.addShape("hyris", HyrisShape)

export default HyrisShape
