import React, { ReactNode, useState } from "react";
import Alert, { AlertColor } from '../../../node_modules/@mui/material/Alert';
import { SnackbarContext } from "./snackbarContext";
import { Snackbar } from "@mui/material";

export const SnackbarProvider: React.FC<ReactNode> = ({ children }) => {
  const [message, setMessage] = useState<string>(
    "Comment t'a fait pour ouvrir ça o_o !"
  );
  const [severity, setSeverity] = useState<AlertColor>("info");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = (message: string, severity: AlertColor = "info"): void => {
    if (message) {
      setMessage(message);
      setSeverity(severity);
      setIsOpen(true);
    }
  };
  const forceClose = () => {
    setIsOpen(false);
  };
  return (
    <SnackbarContext.Provider value={{ open, forceClose }}>
      {children}
      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'bottom'}} open={isOpen} autoHideDuration={6000} onClose={forceClose}>
        <Alert
          elevation={6}
          variant="filled"
          onClose={forceClose}
          severity={severity}
        >
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
