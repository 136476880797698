import { Box, Typography } from "@mui/material"
import { Footer } from "../components/Footer"
import { HeaderBar } from "../components/HeaderBar"
import React from "react"
import { Main, PageTitle } from "../components/StyledElements"
import { CONSTANTS } from "../components/Util"
import { useAuthContext } from "../services/auth"

export const Contact = (): React.ReactElement => {
  const { isAuth } = useAuthContext()
  return (
    <React.Fragment>
      <HeaderBar noSearchBar={!isAuth()} />
      <Main>
        <PageTitle>{"Nous contacter"}</PageTitle>
        <Box padding={2}>
          <Typography component="h3" variant="h3" fontSize={"2rem"} pt={4}>
            {"Demande de prestation"}
          </Typography>
          <Typography component="p" variant="body1" fontSize={"1rem"} sx={{ opacity: 0.7 }}>
            {`A Hyris nous effectuons des prestations pour diverses entreprises et associations. Nous avons l'habitude de couvrir vos événements mais aussi de réaliser des vidéos promotionnelles. Pour toute demande vous pouvez contacter notre adoré vice-président de l'association, ${CONSTANTS.contact.presta.name} (`}
            <a href={`mailto:${CONSTANTS.contact.presta.mail}`} style={{ color: "inherit" }}>
              {CONSTANTS.contact.presta.mail}
            </a>
            {`). Nous tâcherons de vous envoyer un devis dans les plus brefs délais.`}
          </Typography>
          <Typography component="p" variant="body1" fontSize={"1rem"} sx={{ opacity: 0.7 }}>
            {`Pour toute autre demande, adressez-vous au président, ${CONSTANTS.contact.prez.name} (`}
            <a href={`mailto:${CONSTANTS.contact.presta.mail}`} style={{ color: "inherit" }}>
              {CONSTANTS.contact.prez.mail}
            </a>
            {`).`}
          </Typography>
          <Typography component="h3" variant="h3" fontSize={"2rem"} pt={4}>
            {"Pour les associations de CentraleSupélec"}
          </Typography>
          <Typography component="p" variant="body1" fontSize={"1rem"} sx={{ opacity: 0.7 }}>
            {`Pour une couverture d'événement : ${CONSTANTS.contact.prez.name} (`}
            <a href={`mailto:${CONSTANTS.contact.prez.mail}`} style={{ color: "inherit" }}>
              {CONSTANTS.contact.prez.mail}
            </a>
            {`)`}
          </Typography>
          <Typography component="p" variant="body1" fontSize={"1rem"} sx={{ opacity: 0.7 }}>
            {`Pour un prêt de matériel : ${CONSTANTS.contact.matos.name} (`}
            <a href={`mailto:${CONSTANTS.contact.matos.mail}`} style={{ color: "inherit" }}>
              {CONSTANTS.contact.matos.mail}
            </a>
            {`)`}
          </Typography>
          <Typography component="p" variant="body1" fontSize={"1rem"} sx={{ opacity: 0.7 }}>
            {`Pour une demande de régie : ${CONSTANTS.contact.rej.name} (`}
            <a href={`mailto:${CONSTANTS.contact.rej.mail}`} style={{ color: "inherit" }}>
              {CONSTANTS.contact.rej.mail}
            </a>
            {`)`}
          </Typography>
        </Box>
      </Main>
      <Footer />
    </React.Fragment>
  )
}
