import { Box, styled, Typography, useTheme } from "@mui/material"
import { TagType } from "../services/api/tag"

interface PageTitleProps {
  children: React.ReactNode
}

export const PageTitle = ({ children }: PageTitleProps) => {
  return (
    <Typography component="h1" variant="h1" align="center" fontSize={"3rem"} pt={2} sx={{ userSelect: "none" }}>
      {children}
    </Typography>
  )
}

interface PageTitleProps {
  children: React.ReactNode
  zIndex?: number
}

export const Main = ({ children }: PageTitleProps) => {
  const theme = useTheme()
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: theme.palette.element.main.background,
        color: theme.palette.element.main.text,
        minHeight: "200px",
      }}
    >
      {children}
    </Box>
  )
}

interface TagChipProps {
  tag: TagType
}

export const TagChip = ({ tag }: TagChipProps) => {
  return (
    <Box
      display="inline-block"
      sx={{ backgroundColor: "#2C579F", borderRadius: "3px", color: "white", fontSize: "12px", padding: "2px 4px" }}
      maxHeight={"24px"}
      alignContent={"center"}
      justifyContent={"center"}
    >
      {tag.name}
    </Box>
  )
}

export const HoverOverlay = styled(Box)({
  position: "relative",
  overflow: "hidden",

  "&:hover .overlay": {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },

  ".adaptiveBorderRadius": {
    borderRadius: "10px",
    transition: ".3s",
  },

  "&:hover .adaptiveBorderRadius": {
    borderRadius: "0px",
  },
})

export const Overlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "transparent",
  transition: "background-color 0.3s ease",
  zIndex: 1,
})
