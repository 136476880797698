import React from "react"
import { Switch, Route, BrowserRouter } from "react-router-dom"
import { PrivateRoute } from "./components/PrivateRoute"
import { Home } from "./views/Home"
import { Login, VRCallback } from "./views/Login"
import { Video } from "./views/Video"
import { httpClient } from "./services/http"
import { LocalToken } from "./utils/localStorage"
import { UserRole } from "./services/auth/auth.types"
import { Limbo } from "./views/Limbo"
import { NotImplemented } from "./views/NotImplemented"
import { Direct } from "./views/Direct"
import { Directs } from "./views/Directs"
import { Videos, LikedVideos, MostLikedVideos, MostViewedVideos, History } from "./views/Videos"
import { AddVideo } from "./views/AddVideo"
import { PendingVideos } from "./views/PendingVideos"
import { EditVideo } from "./views/EditVideo"
import { AddDirect } from "./views/AddDirect"
import { EditDirect } from "./views/EditDirect"
import { LiveRedirect } from "./components/LiveRedirect"
import { Settings } from "./views/Settings"
import { Contact } from "./views/Contact"
import { SearchVideos } from "./views/Search"
import { useThemeContext } from "./services/ThemeContext"

export const Routes = (): React.ReactElement => {
  const token = LocalToken.get()
  const { mode } = useThemeContext()
  if (token) {
    httpClient.setAuthorization(token)
  }
  return mode === 2 ? (
    <p>{"Hyris a explosé."}</p>
  ) : (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/login" component={Login} />
        <Route path="/contact" component={Contact} />
        <Route path="/portfolio" component={NotImplemented} />
        <Route path="/videos" component={Videos} />
        <Route path="/search/:search" component={SearchVideos} />
        <Route path="/video/:videoId" component={Video} />
        <Route path="/auth/callback" component={VRCallback} />
        <PrivateRoute path="/directs" component={Directs} />
        <PrivateRoute path="/direct/:directId" component={Direct} />
        <PrivateRoute path="/history" component={History} />
        <PrivateRoute path="/liked" component={LikedVideos} />
        <PrivateRoute path="/mostLiked" component={MostLikedVideos} />
        <PrivateRoute path="/mostViewed" component={MostViewedVideos} />
        <PrivateRoute path="/dt" component={LiveRedirect} />
        <PrivateRoute path="/editVideo/:videoId" component={EditVideo} roles={[UserRole.HYRIS, UserRole.GEEK]} />
        <PrivateRoute path="/addVideo" component={AddVideo} roles={[UserRole.HYRIS, UserRole.GEEK]} />
        <PrivateRoute path="/pending" component={PendingVideos} roles={[UserRole.HYRIS, UserRole.GEEK]} />
        <PrivateRoute path="/editDirect/:directId" component={EditDirect} roles={[UserRole.GEEK]} />
        <PrivateRoute path="/addDirect" component={AddDirect} roles={[UserRole.GEEK]} />
        <PrivateRoute path="/settings" component={Settings} roles={[UserRole.GEEK]} />
        <Route path="*" component={Limbo} />
      </Switch>
    </BrowserRouter>
  )
}
