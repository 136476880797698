import { Box } from "@mui/material"
import React, { useState, useEffect, useRef } from "react"
import { Loading } from "./Loading"

interface VideoPlayerProps {
  id: number
  resolution?: number
  raw?: boolean
  autoplay?: boolean
  disableKeyboardShortcuts?: boolean
}

export const VideoPlayer = ({
  id,
  resolution = 1080,
  raw = false,
  autoplay = true,
  disableKeyboardShortcuts = false,
}: VideoPlayerProps): React.ReactElement => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [loading, setLoading] = useState(true)

  const handleCanPlay = () => {
    setLoading(false)
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (!videoRef.current) return
    if (disableKeyboardShortcuts) return

    switch (e.key.toLowerCase()) {
      case " ":
        e.preventDefault()
        if (videoRef.current.paused) {
          videoRef.current.play()
        } else {
          videoRef.current.pause()
        }
        break

      case "f":
        if (document.fullscreenElement) {
          if (document.exitFullscreen) {
            document.exitFullscreen()
          }
        } else {
          if (videoRef.current.requestFullscreen) {
            videoRef.current.requestFullscreen()
          }
        }
        break

      case "arrowright":
        if (videoRef.current.paused) {
          e.preventDefault()
          videoRef.current.currentTime += 1 / 25
        }
        break

      case "arrowleft":
        if (videoRef.current.paused) {
          e.preventDefault()
          videoRef.current.currentTime -= 1 / 25
        }
        break

      case "arrowup":
        e.preventDefault()
        videoRef.current.volume = Math.min(videoRef.current.volume + 0.1, 1)
        break

      case "arrowdown":
        e.preventDefault()
        videoRef.current.volume = Math.max(videoRef.current.volume - 0.1, 0)
        break

      default:
        break
    }
  }

  useEffect(() => {
    if (videoRef.current) {
      const token = localStorage.getItem("token")
      const originalSrc = `/api/media/video/${id}/${raw ? 0 : resolution}`

      if (token) {
        const authSrc = `${originalSrc}?Authorization=Bearer ${token}`
        videoRef.current.src = authSrc
      } else {
        videoRef.current.src = originalSrc
      }
      videoRef.current.load()
    }
  }, [id, raw, resolution])

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [disableKeyboardShortcuts])

  return (
    <Box>
      <video ref={videoRef} width="100%" controls autoPlay={autoplay} onCanPlay={handleCanPlay} />
    </Box>
  )
}
