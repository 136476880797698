/* eslint react/prop-types: 0 */
import { useRef, useEffect } from "react";
import "./hyrisShape";
import mojs from "../../../node_modules/@mojs/core";

const MojsExample = ({ duration }) => {
  const animDom = useRef();
  const bouncyCircle = useRef();

  useEffect(() => {
    // Prevent multiple instansiations on hot reloads
    if (bouncyCircle.current) return;

    // Assign a Shape animation to a ref
    bouncyCircle.current = new mojs.Shape({
      parent: animDom.current,
      shape: "hyris",
      scale: { 1: 2 },
      rotate: { 0: 360 },
      fill: 'none',
      repeat: 999,
      stroke: {"#00fefc": "#fff700"},
      strokeDasharray:  '100%',
      strokeWidth: 1,
      strokeDashoffset: { '-100%' : '200%' },
      duration: duration,
      isShowStart: true,
      isShowEnd: false,
      isYoyo: true,
      easing: 'ease.inout',
    }).play();
  });

  return <div ref={animDom} className="Loader" />;
};

export default MojsExample;
