import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  Container,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
  Typography,
} from "@mui/material"

import { DirectQueryType, DirectType, PagedDirectResponse } from "../services/api/direct"
import { Footer } from "../components/Footer"
import { HeaderBar } from "../components/HeaderBar"
import { Main, PageTitle } from "../components/StyledElements"
import { useDirectAPI } from "../services/api/hooks/useDirectAPI"
import { httpClient } from "../services/http"
import { useSnackbarContext } from "../services/snackbar"
import { useHistory } from "react-router-dom"

const StyledTextField = styled(TextField)({
  width: "100%",
})

const FieldContainer = styled(Grid)({
  padding: "20px",
})

interface HomeDisplayResponse {
  homeDisplay: number
}

export const Settings = (): React.ReactElement => {
  const [directSearch, setDirectSearch] = useState<string>("")
  const [direct, setDirect] = useState<DirectType | null>(null)

  const [homeDisplay, setHomeDisplay] = useState<number>(0)

  const { open: sbOpen } = useSnackbarContext()
  const history = useHistory()

  const { directsData, loading: directsLoading } = useDirectAPI({
    constraint: DirectQueryType.SEARCH,
    search: directSearch,
  })

  const handleDirectFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirect(null)
    setDirectSearch(e.target.value)
  }

  const handleHomeDisplayChange = (e: SelectChangeEvent<number>) => {
    setHomeDisplay(e.target.value as number)
  }

  const handleSubmit = async () => {
    try {
      await httpClient.instance.put(`currentDirect/${direct ? direct.id : -1}`)
      await httpClient.instance.put(`homeDisplay/${homeDisplay}`)
    } catch (error) {
      sbOpen("Une erreur s'est produite", "error")
    } finally {
      history.push(`/`)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { homeDisplay: hd } = await httpClient.instance.get<HomeDisplayResponse>(`homeDisplay`)
        setHomeDisplay(hd)
        const response = await httpClient.instance.get<PagedDirectResponse>(`currentDirect`)
        setDirect((response.directs && response.directs[0]) || null)
      } catch (error) {
        sbOpen("Une erreur s'est produite", "error")
        history.push(`/`)
      }
    }
    fetchData()
  }, [])

  return (
    <React.Fragment>
      <HeaderBar />
      <Main>
        <PageTitle>{"Paramètres généraux"}</PageTitle>
        <Container>
          <Grid container direction="row">
            <FieldContainer item xs={direct ? 10 : 12}>
              <StyledTextField
                label="DT à la une"
                variant="standard"
                value={direct ? direct.title : directSearch}
                onChange={handleDirectFieldChange}
                focused
              />
              {!directsLoading && !direct && directsData.length > 0 && (
                <>
                  {directsData.slice(0, 3).map((direct) => (
                    <Box
                      key={direct.id}
                      sx={{
                        backgroundColor: "primary.main",
                        "&:hover": { backgroundColor: "primary.light" },
                        transition: ".2s",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setDirect(direct)
                        if (homeDisplay === 0) setHomeDisplay(1)
                      }}
                    >
                      <Typography sx={{ padding: 1, pl: 2 }} textAlign="start">
                        {direct.title}
                      </Typography>
                    </Box>
                  ))}
                </>
              )}
            </FieldContainer>
            {direct && (
              <FieldContainer item xs={2}>
                <Typography color="darkgrey" align="center">
                  {direct.id}
                </Typography>
              </FieldContainer>
            )}
            <FieldContainer item xs={12}>
              <Select value={homeDisplay} variant="standard" onChange={handleHomeDisplayChange} sx={{ minWidth: 120 }}>
                <MenuItem value={0}>{"Classique"}</MenuItem>
                <MenuItem value={1}>{"Prochain DT"}</MenuItem>
                <MenuItem value={2}>{"DT en cours"}</MenuItem>
              </Select>
              <FormHelperText>{"Page d'accueil"}</FormHelperText>
            </FieldContainer>
            <FieldContainer item xs={12}>
              <Button variant="contained" color="secondary" onClick={handleSubmit} sx={{ margin: 1 }}>
                {"Valider"}
              </Button>
            </FieldContainer>
          </Grid>
        </Container>
      </Main>
      <Footer />
    </React.Fragment>
  )
}
