import { HeaderBar } from "../components/HeaderBar"
import { Box, Button, CardMedia, Container, Grid, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom"
import { Footer } from "../components/Footer"
import { useDirectAPI } from "../services/api/hooks/useDirectAPI"
import { DirectQueryType, DirectType } from "../services/api/direct"
import { useSnackbarContext } from "../services/snackbar"
import { Loading } from "../components/Loading"
import { VideoSuggestions } from "../components/VideoSuggestions"
import { VideoQueryType } from "../services/api/video"
import { useAuthContext } from "../services/auth"
import { UserRole } from "../services/auth/auth.types"
import { FormatParagraphs, StylelessLink } from "../components/Util"
import { Main, PageTitle } from "../components/StyledElements"
import { isAxiosError } from "axios"

export const Direct = (): React.ReactElement => {
  const { directId } = useParams<{ directId: string }>()
  const { directsData, loading, error, refresh } = useDirectAPI({
    constraint: DirectQueryType.DIRECT,
    id: directId,
  })
  const {
    directsData: directsDataNext,
    loading: loadingNext,
    refresh: refreshNext,
  } = useDirectAPI({ constraint: DirectQueryType.NEXT, id: directId })
  const {
    directsData: directsDataPrev,
    loading: loadingPrev,
    refresh: refreshPrev,
  } = useDirectAPI({
    constraint: DirectQueryType.PREVIOUS,
    id: directId,
  })
  const { open: sbOpen } = useSnackbarContext()
  const history = useHistory()
  const location = useLocation()
  const { role } = useAuthContext()

  const [direct, setDirect] = useState<DirectType>()
  const [posterSource, setPosterSource] = useState<string | null>(null)
  const [redirectLogin, setRedirectLogin] = useState<boolean>(false)

  useEffect(() => {
    const fetchPoster = async () => {
      try {
        const response = await fetch(`/api/media/poster/${directId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })

        const blob = await response.blob()

        const posterURL = URL.createObjectURL(blob)
        setPosterSource(posterURL)
      } catch (error) {
        console.error("Error fetching poster :", error)
      }
    }

    fetchPoster()
  }, [directId])

  useEffect(() => {
    refreshNext(true)
    refreshPrev(true)
    refresh()
  }, [directId])

  useEffect(() => {
    if (directsData && directsData[0]) {
      setDirect(directsData[0])
    }
    if (error) {
      if (error.status === 401) {
        setRedirectLogin(true)
        sbOpen("Veuillez vous connecter", "info")
        return
      }
      sbOpen("Une erreur s'est produite", "error")
      history.push("/")
    }
  }, [directId, loading, directsData, error, status])

  if (error) {
    sbOpen("Une erreur s'est produite", "error")
  }

  if (redirectLogin) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    )
  }

  return !loading && direct ? (
    <React.Fragment>
      <HeaderBar />
      <Main>
        <Box>
          {posterSource && (
            <Box
              sx={{
                position: "absolute",
                top: "0",
                overflow: "hidden",
                width: "100vw",
                minHeight: "100vh",
                maxHeight: "120vh",
              }}
            >
              <CardMedia
                component="img"
                image={posterSource}
                sx={{
                  left: "50%",
                  width: "100%",
                  objectFit: "cover",
                  zIndex: 100,
                  transform: "translateY(min(80px,calc(300px - 30vw)))",
                  opacity: 0.3,
                  filter: "blur(.3vw)",
                  maskImage: "linear-gradient(to bottom, rgba(0,0,0,1) 20%, rgba(0,0,0,0) min(100%, 120vh))",
                }}
              />
            </Box>
          )}
          <Box sx={{ position: "relative", zIndex: 200 }}>
            <Container sx={{ backgroundColor: "none" }}>
              <Grid container spacing={8}>
                <Grid item md={12}>
                  <PageTitle>{direct.title}</PageTitle>
                </Grid>
                <Grid item md={12}>
                  <Typography align="center">
                    <FormatParagraphs content={direct.description} />
                  </Typography>
                </Grid>
              </Grid>
            </Container>
            <Container sx={{ minHeight: "300px" }}>
              {role === UserRole.GEEK && (
                <Button
                  sx={{ margin: 1 }}
                  variant="outlined"
                  color="secondary"
                  onClick={() => history.push(`/editDirect/${direct.id}`)}
                >
                  {"Modifier"}
                </Button>
              )}
              <Grid container>
                <Grid item xs={6} padding={2}>
                  {!loadingPrev && directsDataPrev.length ? (
                    <Typography align="left">
                      <StylelessLink to={`/direct/${directsDataPrev[0]?.id}`}>
                        {`← ${directsDataPrev[0].title}`}
                      </StylelessLink>
                    </Typography>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={6} padding={2}>
                  {!loadingNext && directsDataNext.length ? (
                    <Typography align="right">
                      <StylelessLink to={`/direct/${directsDataNext[0]?.id}`}>
                        {`${directsDataNext[0].title} →`}
                      </StylelessLink>
                    </Typography>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
              <VideoSuggestions query={{ constraint: VideoQueryType.DIRECT, id: direct.id }} md={3} />
            </Container>
          </Box>
        </Box>
      </Main>
      <Box sx={{ position: "relative", zIndex: "300" }}>
        <Footer />
      </Box>
    </React.Fragment>
  ) : (
    <Loading />
  )
}
