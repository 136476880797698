import { HeaderBar } from "../components/HeaderBar"
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  styled,
} from "@mui/material"
import React, { DragEvent, useState } from "react"
import { Footer } from "../components/Footer"
import { DirectType } from "../services/api/direct"
import { useAuthContext } from "../services/auth"
import { UserRole } from "../services/auth/auth.types"
import { useSnackbarContext } from "../services/snackbar"
import { httpClient } from "../services/http"
import { uploadPoster } from "../utils/upload"
import { Main, PageTitle } from "../components/StyledElements"

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
}))

const FieldContainer = styled(Grid)(({ theme }) => ({
  padding: "20px",
}))

export const AddDirect = (): React.ReactElement => {
  const [posterFile, setPosterFile] = useState<File | null>(null)
  const [title, setTitle] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [date, setDate] = useState<string>(new Date().toISOString().split("T")[0])
  const [promotion, setPromotion] = useState<number | null>(null)
  const [privacy, setPrivacy] = useState<number>(1)
  const [liveUrl, setliveUrl] = useState<string>("")

  const [isUploading, setIsUploading] = useState<boolean>(false)

  const [isDraggingPoster, setIsDraggingPoster] = useState(false)

  const { role } = useAuthContext()
  const { open: sbOpen } = useSnackbarContext()

  const handlePosterFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      setPosterFile(file || null)
    }
  }

  const handleDragOverPoster = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingPoster(true)
  }

  const handleDragLeavePoster = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingPoster(false)
  }

  const handleDropPoster = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingPoster(false)
    const files = event.dataTransfer.files
    if (files.length > 0 && (files[0].type === "image/png" || files[0].type === "image/jpeg")) {
      setPosterFile(files[0])
    }
  }

  const handlePrivacyChange = (e: SelectChangeEvent<number>) => {
    setPrivacy(e.target.value as number)
  }

  const flushForm = () => {
    setPosterFile(null)
    setTitle("")
    setDescription("")
    setDate(new Date().toISOString().split("T")[0])
    setPromotion(null)
    setPrivacy(1)
    setliveUrl("")
  }

  const handleSubmit = async () => {
    setIsUploading(true)
    const data = {
      title: title,
      description: description,
      privacy: privacy,
      date: date.toString(),
      promotion: (promotion || -1).toString(),
      liveUrl: liveUrl,
    }

    let directResponse: DirectType | null = null

    try {
      directResponse = await httpClient.instance.post("directs", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    } catch (error) {
      sbOpen("Erreur pendant la création du direct", "error")
    } finally {
      if (!directResponse) {
        sbOpen("Erreur pendant la création du direct", "error")
        setIsUploading(false)
      } else {
        try {
          directResponse && posterFile && (await uploadPoster(directResponse, posterFile, sbOpen))
          sbOpen("Direct créée avec succès", "success")
          flushForm()
        } catch {
          sbOpen("Erreur pendant l'upload", "error")
        } finally {
          setIsUploading(false)
        }
      }
    }
  }

  return (
    <React.Fragment>
      <HeaderBar />
      <Main>
        <PageTitle>{"Ajouter un direct"}</PageTitle>
        <Container maxWidth="xl">
          <Grid container direction="row">
            <Grid item xs={12} md={6} container direction="row">
              <FieldContainer item xs={12}>
                <StyledTextField
                  label="Titre"
                  variant="standard"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  autoFocus
                  focused
                />
              </FieldContainer>
              <FieldContainer item xs={12}>
                <StyledTextField
                  label="Description"
                  variant="outlined"
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  multiline
                  minRows={8}
                  maxRows={12}
                  focused
                />
              </FieldContainer>
              <FieldContainer item xs={12}>
                <StyledTextField
                  label="Lien du live"
                  variant="standard"
                  value={liveUrl}
                  onChange={(e) => setliveUrl(e.target.value)}
                  autoFocus
                  focused
                />
              </FieldContainer>
            </Grid>
            <Grid item xs={12} md={6} container direction="row">
              <FieldContainer item xs={12}>
                <FormControl fullWidth focused>
                  <Input
                    id="poster-input"
                    type="file"
                    onChange={handlePosterFileInputChange}
                    sx={{ display: "none" }}
                    inputProps={{ style: { display: "none" } }}
                  />
                  <Box
                    sx={{
                      border: "2px solid #424242",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      py: 5,
                      cursor: "pointer",
                      backgroundColor: isDraggingPoster ? "primary.dark" : "inherit",
                    }}
                    onClick={() => document.getElementById("poster-input")?.click()}
                    onDragOver={handleDragOverPoster}
                    onDragLeave={handleDragLeavePoster}
                    onDrop={handleDropPoster}
                  >
                    {posterFile ? posterFile.name : "Choisir une affiche (.png ou .jpg)..."}
                  </Box>
                </FormControl>
              </FieldContainer>
              <FieldContainer item xs={6}>
                <StyledTextField
                  label="Date"
                  variant="standard"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  focused
                />
              </FieldContainer>
              <FieldContainer item xs={6}>
                <FormControl fullWidth variant="standard" focused>
                  <InputLabel htmlFor="promotion-input">Promo</InputLabel>
                  <Input
                    id="promotion-input"
                    type="text"
                    value={promotion || ""}
                    onChange={(e) => setPromotion(e.target.value === "" ? null : parseInt(e.target.value))}
                    placeholder=""
                    startAdornment={<InputAdornment position="start">P</InputAdornment>}
                  />
                </FormControl>
              </FieldContainer>
              <FieldContainer item xs={4}>
                <Select value={privacy} variant="standard" onChange={handlePrivacyChange} sx={{ minWidth: 120 }}>
                  {role === UserRole.GEEK && <MenuItem value={0}>Publique</MenuItem>}
                  <MenuItem value={1}>CS</MenuItem>
                  <MenuItem value={2}>Hyris</MenuItem>
                  {role === UserRole.GEEK && <MenuItem value={3}>Archivé</MenuItem>}
                </Select>
                <FormHelperText>{"Accès"}</FormHelperText>
              </FieldContainer>
              <FieldContainer item xs={12}>
                <Button variant="contained" color="secondary" onClick={handleSubmit} disabled={title === ""}>
                  {posterFile ? "Valider & uploader" : "Valider"}
                </Button>
              </FieldContainer>
            </Grid>
          </Grid>
        </Container>
      </Main>
      <Footer />

      <Backdrop sx={{ color: "#fff", zIndex: 300, display: "flex", flexDirection: "column" }} open={isUploading}>
        <CircularProgress color="inherit" />
        <Typography variant="h6" sx={{ mt: 2 }}>
          {"Upload en cours, veuillez ne pas quitter cette page..."}
        </Typography>
      </Backdrop>
    </React.Fragment>
  )
}
