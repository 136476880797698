import React from "react"
import { AuthProvider } from "./services/auth/"
import { SnackbarProvider } from "./services/snackbar"
import { ThemeProviderWrapper } from "./services/ThemeContext"
import { Routes } from "./Routes"

const App = (): React.ReactElement => (
  <>
    <SnackbarProvider>
      <ThemeProviderWrapper>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </ThemeProviderWrapper>
    </SnackbarProvider>
  </>
)

export default App
