import { useState, useEffect } from "react"
import { DirectQuery, DirectType, getDirects } from "../direct"
import axios from "axios"

export interface IFetchDirect {
  directsData: DirectType[]
  loading: boolean
  error: { status: number } | null
  refresh: (resetIfNull?: boolean) => void
  nResults: number
}

export const useDirectAPI = (query: DirectQuery): IFetchDirect => {
  const [directsData, setDirects] = useState<DirectType[]>([])
  const [nResults, setNResults] = useState(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<{ status: number } | null>(null)
  const fetchDirects = async (queryParam: DirectQuery, resetIfNull = false) => {
    setLoading(true)
    try {
      const directData = await getDirects(queryParam)
      setDirects(directData.directs)
      setNResults(directData.nResults)
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        setError({ status: err.status || 500 })
      } else {
        setError({ status: 500 })
      }
      if (resetIfNull) setDirects([])
    } finally {
      setLoading(false)
    }
  }
  const refresh = (resetIfNull = false) => fetchDirects(query, resetIfNull)
  useEffect(() => {
    fetchDirects(query)
  }, [query.search, query.id])
  return { directsData, loading, error, refresh, nResults }
}
