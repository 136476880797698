import { AlertColor } from '../../../node_modules/@mui/material/Alert';
import { createContext } from "react";


export interface SnackbarContextType {
  open: (message: string, severity?: AlertColor)=> void,
  forceClose: () => void,
}

export const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);
