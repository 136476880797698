import { HeaderBar } from "../components/HeaderBar"
import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  styled,
} from "@mui/material"
import React, { DragEvent, useState } from "react"
import { Footer } from "../components/Footer"
import { useDirectAPI } from "../services/api/hooks/useDirectAPI"
import { DirectQueryType, DirectType } from "../services/api/direct"
import { useAuthContext } from "../services/auth"
import { UserRole } from "../services/auth/auth.types"
import { TagType } from "../services/api/tag"
import { useTagAPI } from "../services/api/hooks/useTagAPI"
import { useSnackbarContext } from "../services/snackbar"
import { httpClient } from "../services/http"
import { uploadThumbnail, uploadVideo } from "../utils/upload"
import { VideoType } from "../services/api/video"
import { Main, PageTitle } from "../components/StyledElements"

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
}))

const FieldContainer = styled(Grid)(({ theme }) => ({
  padding: "20px",
}))

export const AddVideo = (): React.ReactElement => {
  const [videoFile, setVideoFile] = useState<File | null>(null)
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null)
  const [title, setTitle] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [date, setDate] = useState<string>(new Date().toISOString().split("T")[0])
  const [promotion, setPromotion] = useState<number | null>(null)
  const [directSearch, setDirectSearch] = useState<string>("")
  const [direct, setDirect] = useState<DirectType | null>(null)
  const [privacy, setPrivacy] = useState<number>(1)
  const [tags, setTags] = useState<TagType[]>([])
  const [isUploading, setIsUploading] = useState<boolean>(false)

  const [isDraggingVideo, setIsDraggingVideo] = useState(false)
  const [isDraggingThumbnail, setIsDraggingThumbnail] = useState(false)

  const { role } = useAuthContext()
  const { open: sbOpen } = useSnackbarContext()

  const { directsData, loading: directsLoading } = useDirectAPI({
    constraint: DirectQueryType.SEARCH,
    search: directSearch,
  })
  const { tagsData, loading: tagsLoading } = useTagAPI({})

  const handleVideoFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file?.type === "video/mp4") {
      setVideoFile(file || null)
    }
  }

  const handleDragOverVideo = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingVideo(true)
  }

  const handleDragLeaveVideo = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingVideo(false)
  }

  const handleDropVideo = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingVideo(false)
    const files = event.dataTransfer.files
    if (files.length > 0 && files[0].type === "video/mp4") {
      setVideoFile(files[0])
    }
  }

  const handleThumbnailFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      setThumbnailFile(file || null)
    }
  }

  const handleDragOverThumbnail = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingThumbnail(true)
  }

  const handleDragLeaveThumbnail = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingThumbnail(false)
  }

  const handleDropThumbnail = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingThumbnail(false)
    const files = event.dataTransfer.files
    if (files.length > 0 && (files[0].type === "image/png" || files[0].type === "image/jpeg")) {
      setThumbnailFile(files[0])
    }
  }

  const handleDirectFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirect(null)
    setDirectSearch(e.target.value)
  }

  const handlePrivacyChange = (e: SelectChangeEvent<number>) => {
    setPrivacy(e.target.value as number)
  }

  const addTag = (tag: TagType) => {
    setTags([...tags, tag])
  }

  const removeTag = (id: number) => {
    setTags(tags.filter((tag) => tag.id != id))
  }

  const flushForm = () => {
    setVideoFile(null)
    setThumbnailFile(null)
    setTitle("")
    setDescription("")
    setDate(new Date().toISOString().split("T")[0])
    setPromotion(null)
    setDirectSearch("")
    setDirect(null)
    setPrivacy(1)
    setTags([])
  }

  const handleSubmit = async () => {
    setIsUploading(true)
    const data = {
      title: title,
      description: description,
      privacy: privacy,
      date: date.toString(),
      promotion: (promotion || -1).toString(),
      directId: (direct?.id || -1).toString(),
      tagIds: tags.map((tag) => tag.id),
    }

    let videoResponse: VideoType | null = null

    try {
      videoResponse = await httpClient.instance.post("videos", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    } catch (error) {
      sbOpen("Erreur pendant la création de la vidéo", "error")
    } finally {
      if (!videoResponse) {
        sbOpen("Erreur pendant la création de la vidéo", "error")
      }
      try {
        videoResponse && videoFile && (await uploadVideo(videoResponse, videoFile, sbOpen))
        videoResponse && thumbnailFile && (await uploadThumbnail(videoResponse, thumbnailFile, sbOpen))
        sbOpen("Vidéo créée avec succès", "success")
        flushForm()
      } catch {
        sbOpen("Erreur pendant la création de la vidéo...", "error")
      } finally {
        setIsUploading(false)
      }
    }
  }

  return (
    <React.Fragment>
      <HeaderBar />
      <Main>
        <PageTitle>{"Ajouter une vidéo"}</PageTitle>
        <Container maxWidth="xl">
          <Grid container direction="row">
            <FieldContainer item xs={12} md={6}>
              <FormControl fullWidth focused>
                <Input
                  id="video-file-input"
                  type="file"
                  onChange={handleVideoFileInputChange}
                  sx={{ display: "none" }}
                  inputProps={{ style: { display: "none" } }}
                />
                <Box
                  sx={{
                    border: "2px solid #424242",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    py: 5,
                    cursor: "pointer",
                    backgroundColor: isDraggingVideo ? "primary.dark" : "inherit",
                  }}
                  onClick={() => document.getElementById("video-file-input")?.click()}
                  onDragOver={handleDragOverVideo}
                  onDragLeave={handleDragLeaveVideo}
                  onDrop={handleDropVideo}
                >
                  {videoFile ? videoFile.name : "Choisir un fichier vidéo (.mp4)..."}
                </Box>
              </FormControl>
            </FieldContainer>
            <FieldContainer item xs={12} md={6}>
              <FormControl fullWidth focused>
                <Input
                  id="thumbnail-input"
                  type="file"
                  onChange={handleThumbnailFileInputChange}
                  sx={{ display: "none" }}
                  inputProps={{ style: { display: "none" } }}
                />
                <Box
                  sx={{
                    border: "2px solid #424242",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    py: 5,
                    cursor: "pointer",
                    backgroundColor: isDraggingThumbnail ? "primary.dark" : "inherit",
                  }}
                  onClick={() => document.getElementById("thumbnail-input")?.click()}
                  onDragOver={handleDragOverThumbnail}
                  onDragLeave={handleDragLeaveThumbnail}
                  onDrop={handleDropThumbnail}
                >
                  {thumbnailFile ? thumbnailFile.name : "Choisir une miniature (.png ou .jpg)..."}
                </Box>
              </FormControl>
            </FieldContainer>
            <Grid item xs={12} md={6} container direction="row">
              <FieldContainer item xs={12}>
                <StyledTextField
                  label="Titre"
                  variant="standard"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  autoFocus
                  focused
                />
              </FieldContainer>
              <FieldContainer item xs={12}>
                <StyledTextField
                  label="Description"
                  variant="outlined"
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  multiline
                  minRows={8}
                  maxRows={12}
                  focused
                />
              </FieldContainer>
            </Grid>
            <Grid item xs={12} md={6} container direction="row">
              <FieldContainer item xs={6}>
                <StyledTextField
                  label="Date"
                  variant="standard"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  focused
                />
              </FieldContainer>
              <FieldContainer item xs={6}>
                <FormControl fullWidth variant="standard" focused>
                  <InputLabel htmlFor="promotion-input">Promo</InputLabel>
                  <Input
                    id="promotion-input"
                    type="text"
                    value={promotion || ""}
                    onChange={(e) => setPromotion(e.target.value === "" ? null : parseInt(e.target.value))}
                    placeholder=""
                    startAdornment={<InputAdornment position="start">P</InputAdornment>}
                  />
                </FormControl>
              </FieldContainer>
              <FieldContainer item xs={direct ? 10 : 12}>
                <StyledTextField
                  label="Direct associé"
                  variant="standard"
                  value={direct ? direct.title : directSearch}
                  onChange={handleDirectFieldChange}
                  focused
                />
                {!directsLoading && !direct && directsData.length > 0 && (
                  <>
                    {directsData.slice(0, 3).map((direct) => (
                      <Box
                        key={direct.id}
                        sx={{
                          backgroundColor: "primary.main",
                          "&:hover": { backgroundColor: "primary.light" },
                          transition: ".2s",
                          cursor: "pointer",
                        }}
                        onClick={() => setDirect(direct)}
                      >
                        <Typography sx={{ padding: 1, pl: 2 }} textAlign="start">
                          {direct.title}
                        </Typography>
                      </Box>
                    ))}
                  </>
                )}
              </FieldContainer>
              {direct && (
                <FieldContainer item xs={2}>
                  <Typography color="darkgrey" align="center">
                    {direct.id}
                  </Typography>
                </FieldContainer>
              )}
              <FieldContainer item xs={4}>
                <Select value={privacy} variant="standard" onChange={handlePrivacyChange} sx={{ minWidth: 120 }}>
                  {role === UserRole.GEEK && <MenuItem value={0}>Publique</MenuItem>}
                  <MenuItem value={1}>CS</MenuItem>
                  <MenuItem value={2}>Hyris</MenuItem>
                  {role === UserRole.GEEK && <MenuItem value={3}>Archivée</MenuItem>}
                </Select>
                <FormHelperText>{"Accès"}</FormHelperText>
              </FieldContainer>
              {!tagsLoading && tagsData.length != 0 && (
                <FieldContainer item xs={8}>
                  <Typography pb={2} color="primary">
                    {"Catégories"}
                  </Typography>
                  {tagsData.map((tag) => (
                    <Chip
                      key={tag.id}
                      color={tags.some((t) => t.id === tag.id) ? "secondary" : "primary"}
                      label={tag.name}
                      onClick={tags.some((t) => t.id === tag.id) ? () => removeTag(tag.id) : () => addTag(tag)}
                      sx={{ margin: 1 }}
                    />
                  ))}
                </FieldContainer>
              )}
              <FieldContainer item xs={12}>
                <Button variant="contained" color="secondary" onClick={handleSubmit} disabled={title === ""}>
                  {videoFile ? "Valider & uploader" : "Valider"}
                </Button>
              </FieldContainer>
            </Grid>
          </Grid>
        </Container>
      </Main>
      <Footer />

      <Backdrop sx={{ color: "#fff", zIndex: 300, display: "flex", flexDirection: "column" }} open={isUploading}>
        <CircularProgress color="inherit" />
        <Typography variant="h6" sx={{ mt: 2 }}>
          {"Upload en cours, veuillez ne pas quitter cette page..."}
        </Typography>
      </Backdrop>
    </React.Fragment>
  )
}
