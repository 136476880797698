import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { httpClient } from "../services/http"
import { useSnackbarContext } from "../services/snackbar"

interface liveUrlResponse {
  url: string
}

export const LiveRedirect = () => {
  const [url, setURL] = useState<string>("")
  const history = useHistory()
  const { open: sbOpen } = useSnackbarContext()

  useEffect(() => {
    const fetchURL = async () => {
      const { url }: liveUrlResponse = await httpClient.instance.get<liveUrlResponse>(`/currentLive`)
      if (url.length) window.location.replace(url)
      else {
        sbOpen("Il n'y a pas de DT en cours...", "error")
        history.push("/")
      }
    }
    fetchURL()
  }, [])
  return <></>
}
