import {
  createTheme,
  PaletteColor,
  PaletteColorOptions,
} from "@mui/material/styles"

interface PaletteElementsOptions {
  main: {
    background: string
    text: string
    subText: string
    border: string
  }
  videoControls: {
    background: string
    text: string
    subText: string
    border: string
  }
  header: {
    background: string
    icon: string
    border: string
  }
  footer: {
    background: string
    icon: string
    text: string
    subText: string
    border: string
  }
  searchBar: {
    background: string
    backgroundHover: string
    icon: string
  }
  sideBar: {
    background: string
    icon: string
    categoryTitle: string
    text: string
    border: string
    buttonHover: string
    buttonHoverBorder: string
    alpha: number
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    like: PaletteColor
    soft: string
    element: PaletteElementsOptions
  }

  interface PaletteOptions {
    like?: PaletteColorOptions
    soft?: string
    element: PaletteElementsOptions
  }
}

export const defaultTheme = createTheme({
  palette: {
    common: { black: "#000", white: "#fff" },
    background: { paper: "#060606", default: "#000" },
    primary: {
      light: "#333333",
      main: "#222222",
      dark: "#111111",
      contrastText: "#fff",
    },
    secondary: {
      main: "#4A90E2",
      contrastText: "#fff",
    },
    soft: "#888",
    like: {
      main: "#519F51",
      dark: "#3D803D",
      contrastText: "#fff",
    },
    error: {
      light: "rgba(255, 0, 0, 1)",
      main: "rgba(255, 0, 0, 1)",
      dark: "rgba(255, 0, 0, 1)",
      contrastText: "#fff",
    },
    text: {
      primary: "#fff",
      secondary: "#808080",
      disabled: "rgba(97, 97, 97, 1)",
    },
    mode: "dark",
    element: {
      main: {
        background: "#0b0b0b",
        text: "#fff",
        subText: "#808080",
        border: "#A9A9A9",
      },
      videoControls: {
        background: "#111111",
        text: "#fff",
        subText: "#808080",
        border: "#D3D3D3",
      },
      header: {
        background: "#00132B",
        icon: "#fff",
        border: "#fff",
      },
      footer: {
        background: "#00132B",
        icon: "#fff",
        border: "#fff",
        text: "#fff",
        subText: "#808080",
      },
      searchBar: {
        background: "#0C315E",
        backgroundHover: "#0E4484",
        icon: "#fff",
      },
      sideBar: {
        background: "#00132B",
        icon: "#D3D3D3",
        categoryTitle: "#808080",
        text: "#fff",
        border: "#808080",
        buttonHover: "#4A90E2",
        buttonHoverBorder: "#fff",
        alpha: 0.4,
      },
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h3: {
      fontFamily: [
        "Avant Garde",
        "Avantgarde",
        "Century Gothic",
        "CenturyGothic",
        "AppleGothic",
        "sans-serif",
      ].join(","),
      fontWeight: 500,
    },
    h1: {
      fontFamily: [
        "Avant Garde",
        "Avantgarde",
        "Century Gothic",
        "CenturyGothic",
        "AppleGothic",
        "sans-serif",
      ].join(","),
    },
    h5: {
      fontFamily: [
        "Avant Garde",
        "Avantgarde",
        "Century Gothic",
        "CenturyGothic",
        "AppleGothic",
        "sans-serif",
      ].join(","),
      fontWeight: 600,
    },
  },
})

export const lightTheme = createTheme({
  palette: {
    common: { black: "#000", white: "#fff" },
    background: { paper: "#e8e8e8", default: "#fff" },
    primary: {
      light: "#eeeeee",
      main: "#dddddd",
      dark: "#cccccc",
      contrastText: "#000",
    },
    secondary: {
      main: "#4A90E2",
      contrastText: "#fff",
    },
    error: {
      light: "rgba(255, 0, 0, 1)",
      main: "rgba(255, 0, 0, 1)",
      dark: "rgba(255, 0, 0, 1)",
      contrastText: "#fff",
    },
    text: {
      primary: "#000000",
      secondary: "#505050",
      disabled: "rgba(97, 97, 97, 1)",
    },
    mode: "light",
    element: {
      main: {
        background: "#EDEDED",
        text: "#000",
        subText: "#808080",
        border: "#A9A9A9",
      },
      videoControls: {
        background: "#dddddd",
        text: "#000",
        subText: "#808080",
        border: "#aaaaaa",
      },
      header: {
        background: "#fff",
        icon: "#000",
        border: "#000",
      },
      footer: {
        background: "#fff",
        icon: "#fff",
        border: "#000",
        text: "#000",
        subText: "#808080",
      },
      searchBar: {
        background: "#dddddd",
        backgroundHover: "#eeeeee",
        icon: "#000",
      },
      sideBar: {
        background: "#fff",
        icon: "#333333",
        categoryTitle: "#808080",
        text: "#000",
        border: "#808080",
        buttonHover: "#4a90e2",
        buttonHoverBorder: "#000",
        alpha: 1,
      },
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h3: {
      fontFamily: [
        "Avant Garde",
        "Avantgarde",
        "Century Gothic",
        "CenturyGothic",
        "AppleGothic",
        "sans-serif",
      ].join(","),
      fontWeight: 500,
    },
    h1: {
      fontFamily: [
        "Avant Garde",
        "Avantgarde",
        "Century Gothic",
        "CenturyGothic",
        "AppleGothic",
        "sans-serif",
      ].join(","),
    },
    h5: {
      fontFamily: [
        "Avant Garde",
        "Avantgarde",
        "Century Gothic",
        "CenturyGothic",
        "AppleGothic",
        "sans-serif",
      ].join(","),
      fontWeight: 600,
    },
  },
})
