import { HeaderBar } from "../components/HeaderBar"
import { Loading } from "../components/Loading"
import { VideoList } from "../components/VideoSuggestions"
import { useVideoAPI } from "../services/api/hooks/useVideoAPI"
import { useSnackbarContext } from "../services/snackbar"
import { Box, Button, Container, Grid, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom"
import { VideoQueryType, VideoType } from "../services/api/video"
import { Footer } from "../components/Footer"
import { VideoPlayer } from "../components/VideoPlayer"
import { VideoControls } from "../components/VideoControls"
import { Main } from "../components/StyledElements"
import { useAuthContext } from "../services/auth"
import { UserRole } from "../services/auth/auth.types"
import { StylelessLink } from "../components/Util"
import { VideoThumbnail } from "../components/VideoThumbnail"
import { httpClient } from "../services/http"
import { useDirectAPI } from "../services/api/hooks/useDirectAPI"
import { DirectQueryType } from "../services/api/direct"

export const Video = (): React.ReactElement => {
  const { videoId } = useParams<{ videoId: string }>()
  const { open: sbOpen } = useSnackbarContext()
  const history = useHistory()
  const location = useLocation()
  const { videosData, loading, error, refresh } = useVideoAPI({
    constraint: VideoQueryType.VIDEO,
    id: parseInt(videoId),
  })
  const { videosData: randomVideosData, refresh: refreshRandom } = useVideoAPI({
    constraint: VideoQueryType.RANDOM,
    id: parseInt(videoId),
  })
  const { role } = useAuthContext()
  const [video, setVideo] = useState<VideoType | undefined>()
  const [resolution, setResolution] = useState<number>(1080)
  const [searchFocused, setSearchFocused] = useState<boolean>(false)
  const [redirectLogin, setRedirectLogin] = useState<boolean>(false)

  useEffect(() => {
    refresh()
    refreshRandom()
    httpClient.instance.put(`/videos/history/${videoId}`)
  }, [videoId])

  useEffect(() => {
    if (videosData && videosData[0]) {
      setVideo(videosData[0])
    }
    if (error) {
      if (error.status === 401) {
        setRedirectLogin(true)
        sbOpen("Veuillez vous connecter", "info")
        return
      }
      sbOpen("Une erreur s'est produite", "error")
      history.push("/")
    }
  }, [videoId, loading, videosData, error])

  if (redirectLogin) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    )
  }

  return !loading && video ? (
    <React.Fragment>
      <HeaderBar setSearchFocus={setSearchFocused} />
      <Main>
        <Container>
          <Grid container spacing={8}>
            <Grid item md={12}>
              <Box mt={2}>
                <VideoPlayer id={video.id} resolution={resolution} disableKeyboardShortcuts={searchFocused} />
                <VideoControls video={video} resolution={resolution} setResolution={setResolution} />
                {(role === UserRole.HYRIS || role === UserRole.GEEK) && (
                  <Button
                    sx={{ margin: 1 }}
                    variant="outlined"
                    color="secondary"
                    onClick={() => history.push(`/editVideo/${video.id}`)}
                  >
                    {"Modifier"}
                  </Button>
                )}
              </Box>
            </Grid>
            <SameDirectSuggesions video={video} />
          </Grid>
        </Container>
        {randomVideosData && randomVideosData.length ? (
          <Box borderTop="1px solid darkgray" sx={{ py: 5, px: 3 }}>
            <Typography component="h3" variant="h3" fontSize={"2rem"}>
              <StylelessLink to="/videos">{"Vidéos au hasard"}</StylelessLink>
            </Typography>
            <Grid container spacing={1} direction="row" sx={{ py: 2 }}>
              {randomVideosData.slice(0, 6).map((video) => (
                <Grid key={video.id} item xs={12} sm={4} md={2}>
                  <VideoThumbnail video={video} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <></>
        )}
      </Main>
      <Footer />
    </React.Fragment>
  ) : (
    <Loading />
  )
}

interface SameDirectSuggestionsProps {
  video: VideoType
}

export const SameDirectSuggesions = ({ video }: SameDirectSuggestionsProps): React.ReactElement => {
  const directSuggestions = useVideoAPI({
    constraint: VideoQueryType.DIRECT,
    id: video.directId,
    exclude: String(video.id),
  })
  const direct = useDirectAPI({
    id: `${video.directId || -1}`,
    constraint: DirectQueryType.DIRECT,
  })

  useEffect(() => {
    directSuggestions.refresh()
  }, [video.id])

  return directSuggestions && directSuggestions.videosData.length ? (
    <Grid item md={12}>
      <Container>
        <Typography component="h3" variant="h3" fontSize={"2rem"}>
          <StylelessLink to={`/direct/${video.directId}`}>{"Du même DT"}</StylelessLink>
        </Typography>
        {direct.directsData?.length ? (
          <Typography component="h3" variant="h3" fontSize={"1.5rem"} color={"grey"}>
            <StylelessLink to={`/direct/${video.directId}`}>{direct.directsData[0].title}</StylelessLink>
          </Typography>
        ) : (
          <></>
        )}
        <VideoList videos={directSuggestions.videosData} />
      </Container>
    </Grid>
  ) : (
    <></>
  )
}
