import { httpClient } from "../http"
import { formatSearch } from "../../components/Util"

export interface DirectType {
  id: number
  title: string
  description: string
  promotion: number
  poster: string
  privacy: number
  date: string
  liveUrl: string
  pcVidId: number
}

export enum DirectQueryType {
  DIRECT,
  NEXT,
  PREVIOUS,
  LATEST,
  SEARCH,
  UPCOMING,
}

export interface DirectQuery {
  constraint?: DirectQueryType
  id?: string
  search?: string
  page?: number
  pageSize?: number
}

export class PagedDirectResponse {
  directs: DirectType[] = []
  nResults = 0
}

export const getDirects = async (
  query: DirectQuery
): Promise<PagedDirectResponse> => {
  let param = "directs"
  const params = new URLSearchParams()

  switch (query.constraint) {
    case DirectQueryType.DIRECT:
      if (!query.id || query.id === "-1") return new PagedDirectResponse()
      param += `/${query.id}`
      break

    case DirectQueryType.UPCOMING:
      param += "/currentDirect"
      break

    case DirectQueryType.NEXT:
      if (!query.id || query.id === "-1") return new PagedDirectResponse()
      param += `/next/${query.id}`
      break

    case DirectQueryType.PREVIOUS:
      if (!query.id || query.id === "-1") return new PagedDirectResponse()
      param += `/previous/${query.id}`
      break

    case DirectQueryType.LATEST:
      if (query.page) params.set("page", String(query.page))
      break

    case DirectQueryType.SEARCH:
      param += `/search/${formatSearch(query.search || "")}`
      break

    default:
      throw new Error("Invalid DirectQuery constraint")
  }

  if (query.page) params.set("page", String(query.page))
  if (query.pageSize) params.set("page_size", String(query.pageSize))

  const queryString = params.toString()
  return httpClient.instance.get<PagedDirectResponse>(
    queryString ? `${param}?${queryString}` : param
  )
}
