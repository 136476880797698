import { AlertColor } from "@mui/material"
import { VideoType } from "../services/api/video"
import { httpClient } from "../services/http"
import { DirectType } from "services/api/direct"

export const uploadVideo = async (
  video: VideoType,
  videoFile: File,
  sbOpen: (message: string, severity?: AlertColor | undefined) => void
) => {
  console.log("Uploading video...")

  const formData = new FormData()
  formData.append("video_upload", videoFile)

  try {
    const response: any = await httpClient.instance.post(
      `media/video/${video.id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )

    if (response.ok) {
      sbOpen("Vidéo uploadée avec succès", "success")
    } else {
      throw new Error("Erreur pendant l'upload")
    }
  } catch (error) {
    sbOpen("Erreur pendant l'upload", "error")
    console.log(error)
  }
}

export const uploadThumbnail = async (
  video: VideoType,
  thumbnailFile: File,
  sbOpen: (message: string, severity?: AlertColor | undefined) => void
) => {
  console.log("Uploading thumbnail...")

  const formData = new FormData()
  formData.append("thumbnail_upload", thumbnailFile)

  try {
    const response: any = await httpClient.instance.post(
      `media/thumbnail/${video.id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )

    if (response.ok) {
      sbOpen("Miniature uploadée avec succès", "success")
    } else {
      throw new Error("Erreur pendant l'upload")
    }
  } catch (error) {
    sbOpen("Erreur pendant l'upload", "error")
    console.log(error)
  }
}

export const uploadPoster = async (
  direct: DirectType,
  posterFile: File,
  sbOpen: (message: string, severity?: AlertColor | undefined) => void
) => {
  console.log("Uploading poster...")

  const formData = new FormData()
  formData.append("poster_upload", posterFile)

  try {
    const response: any = await httpClient.instance.post(
      `media/poster/${direct.id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )

    if (response.ok) {
      sbOpen("Poster uploadé avec succès", "success")
    } else {
      sbOpen("Erreur pendant l'upload", "error")
    }
  } catch (error) {
    sbOpen("Erreur pendant l'upload", "error")
    console.log(error)
  }
}
