import { useState, useEffect } from "react"
import { getVideos, VideoQuery, VideoType } from "../video"
import axios from "axios"

export interface IFetchVideo {
  videosData: VideoType[]
  loading: boolean
  error: { status: number } | null
  refresh: () => void
  nResults: number
}

export const useVideoAPI = (query: VideoQuery): IFetchVideo => {
  const [videosData, setVideos] = useState<VideoType[]>([])
  const [nResults, setNResults] = useState(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<{ status: number } | null>(null)
  const fetchVideos = async (queryParam: VideoQuery, resetIfNull = false) => {
    setLoading(true)
    try {
      const videoData = await getVideos(queryParam)
      setVideos(videoData.videos)
      setNResults(videoData.nResults)
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        setError({ status: err.status || 500 })
      } else {
        setError({ status: 500 })
      }
      if (resetIfNull) setVideos([])
    } finally {
      setLoading(false)
    }
  }
  const refresh = (resetIfNull = false) => fetchVideos(query, resetIfNull)
  useEffect(() => {
    fetchVideos(query)
  }, [query.search])
  return { videosData, loading, error, refresh, nResults }
}
