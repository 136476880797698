import React, { useEffect, useState } from "react"
import { TextField, Button, Paper, Avatar, Typography, Box, Grid, Hidden } from "../../node_modules/@mui/material"
import { useAuthContext } from "../services/auth"
import { Copyright } from "../components/Copyright"
import { useSnackbarContext } from "../services/snackbar"
import { useHistory, useLocation, Redirect } from "react-router-dom"
import { ReactComponent as HyrisMinimal } from "../assets/svg/hyris-minimal.svg"

export const Login = (): React.ReactElement => {
  const history = useHistory()
  const location = useLocation<{ from: { pathname: string } }>()
  const { open: sbOpen } = useSnackbarContext()
  const [username, setUsername] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const { login, isAuth, loading } = useAuthContext()
  const handleSubmit = (e: React.KeyboardEvent | React.MouseEvent): void => {
    if ((e as React.MouseEvent).type === "click" || (e as React.KeyboardEvent).key === "Enter") {
      submit()
    }
  }
  const handleVRlogin = () => {
    const { from } = location.state || { from: { pathname: "/" } }
    sessionStorage.setItem("VRLoginRedirect", from.pathname)
    window.location.href = `/api/login/vr`
  }
  const submit = async () => {
    try {
      await login({ username, password })
      const { from } = location.state || { from: { pathname: "/" } }
      sbOpen("Connexion réussie ! Bienvenue", "success")
      history.replace(from)
    } catch (err: any) {
      if (err.response?.status === 401) {
        sbOpen("Mot de passe invalide", "error")
      } else {
        sbOpen(String(err), "error")
      }
    }
  }

  return isAuth() && !loading ? (
    <Redirect to="/" />
  ) : (
    <Grid
      container
      component="main"
      sx={{
        height: "100vh",
      }}
    >
      <Hidden mdDown>
        <Grid
          item
          md={7}
          sx={{
            maxHeight: "100%",
          }}
        >
          <Box
            component="video"
            autoPlay
            loop
            muted
            sx={{
              objectFit: "cover",
              height: "100%",
            }}
          >
            <source src="/video-banner.mp4" type="video/mp4" />
            <source src="/video-banner.mp4" type="video/webm" />
          </Box>
        </Grid>
      </Hidden>

      <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
        <Box sx={{ position: "relative", zIndex: "300" }}>
          <Box
            sx={{
              mx: 4,
              my: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                margin: 1,
                backgroundColor: "transparent",
              }}
            >
              <HyrisMinimal width="30" height="30" fill="white" />
            </Avatar>
            <Typography component="h1" variant="h5">
              {"Connexion"}
            </Typography>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              onClick={handleVRlogin}
              sx={{ p: 4, my: 3, mx: 0 }}
            >
              {"Connexion avec ViaRézo"}
            </Button>
            <Typography component="h1" variant="h5" alignSelf="start">
              {"Login membre"}
            </Typography>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                submit()
              }}
              style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}
            >
              <TextField
                variant="filled"
                margin="normal"
                color="secondary"
                fullWidth
                label="Identifiant"
                name="username"
                autoComplete="username"
                value={username}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.currentTarget.value)}
                onKeyPress={handleSubmit}
              />
              <TextField
                variant="filled"
                margin="normal"
                color="secondary"
                fullWidth
                label="Mot de passe"
                name="password"
                autoComplete="current-password"
                type="password"
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)}
                onKeyPress={handleSubmit}
              />
              <Button
                type="submit"
                fullWidth
                color="secondary"
                variant="outlined"
                sx={{
                  mt: 3,
                  mb: 2,
                  mx: 0,
                }}
              >
                {"Valider"}
              </Button>
            </form>
            <Button
              fullWidth
              color="error"
              variant="outlined"
              sx={{
                mt: 2,
                mb: 2,
                mx: 0,
              }}
              onClick={() => history.push("/")}
            >
              {"Retour"}
            </Button>
          </Box>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export const VRCallback = () => {
  const history = useHistory()
  const location = useLocation()
  const { open: sbOpen } = useSnackbarContext()
  const { VRlogin } = useAuthContext()

  useEffect(() => {
    const fetchToken = async () => {
      try {
        await VRlogin(location.search)
        sbOpen("Connexion réussie ! Bienvenue", "success")
      } catch (err: any) {
        sbOpen("Erreur de connexion...", "error")
      } finally {
        const redirectAfterVR = sessionStorage.getItem("VRLoginRedirect") || "/"
        sessionStorage.removeItem("VRLoginRedirect")
        history.replace(redirectAfterVR)
      }
    }

    fetchToken()
  }, [history, location.search])

  return <p>{"Loading..."}</p>
}
