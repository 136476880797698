import { UserRole } from "../services/auth/auth.types"
import React from "react"
import { Redirect, Route, RouteComponentProps } from "react-router-dom"
import { useAuthContext } from "../services/auth/hooks/useAuthContext"
import { Loading } from "./Loading"

interface PrivateRouteProps {
  component: React.FC<RouteComponentProps>
  path: string
  roles?: UserRole[]
  exact?: boolean
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component, roles, ...rest }) => {
  const { isAuth, role, loading } = useAuthContext()
  const hasAccess = (isAuth: boolean, role: UserRole | null) => {
    if (!roles || !roles.length) {
      return isAuth
    } else if (role) {
      return roles.includes(role)
    }
    return false
  }
  return loading ? (
    <Loading />
  ) : (
    <Route
      {...rest}
      render={(props: RouteComponentProps) =>
        hasAccess(isAuth(), role) ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}
