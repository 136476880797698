import { HeaderBar } from "../components/HeaderBar"
import { Box, Button, Container, Grid, Typography } from "@mui/material"
import React, { useState } from "react"
import { Footer } from "../components/Footer"
import { useVideoAPI } from "../services/api/hooks/useVideoAPI"
import { VideoQueryType, VideoType } from "../services/api/video"
import { VideoPlayer } from "../components/VideoPlayer"
import AspectRatioBox from "../components/AspectRatioBox"
import { httpClient } from "../services/http"
import { useSnackbarContext } from "../services/snackbar"
import { VideoThumbnailImage } from "../components/VideoThumbnail"
import { useHistory } from "react-router-dom"
import { Main, PageTitle } from "../components/StyledElements"
import { ClickAwayListener, Unstable_Popup as Popup } from "@mui/base"

export const PendingVideos = (): React.ReactElement => {
  const {
    videosData,
    loading: videosLoading,
    refresh,
  } = useVideoAPI({
    constraint: VideoQueryType.PENDING,
  })
  const { open: sbOpen } = useSnackbarContext()

  const encodeAllVideos = async () => {
    try {
      await Promise.all(
        videosData
          .filter((video) => video.processing === 1)
          .map(async (video) => {
            try {
              await httpClient.instance.post(`encode/${video.id}`)
            } catch {
              sbOpen("Error pendant l'encodage", "error")
            }
          })
      )
      refresh()
    } catch (error) {
      sbOpen("Une erreur s'est produite", "error")
    }
  }

  return (
    <React.Fragment>
      <HeaderBar />
      <Main>
        <PageTitle>{"Vidéos en attente"}</PageTitle>
        {videosData && (
          <Container maxWidth="xl">
            {videosData.length > 1 ? (
              <Box padding={4} gap={1}>
                <Button sx={{ margin: 1 }} variant="contained" color="secondary" onClick={() => encodeAllVideos()}>
                  {"Tout encoder et publier"}
                </Button>
              </Box>
            ) : (
              <></>
            )}
            {videosData.map((video) => (
              <PendingVideoElement video={video} refresh={refresh} key={video.id} />
            ))}
          </Container>
        )}
      </Main>
      <Footer />
    </React.Fragment>
  )
}

interface PendingVideoElementProps {
  video: VideoType
  refresh: () => void
}

const PendingVideoElement = ({ video, refresh }: PendingVideoElementProps): React.ReactElement => {
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false)
  const history = useHistory()
  const { open: sbOpen } = useSnackbarContext()

  const deleteVideo = async () => {
    try {
      video.processing && (await httpClient.instance.delete(`media/video/${video.id}`))
      await httpClient.instance.delete(`videos/${video.id}`)
    } finally {
      refresh()
    }
  }

  const encodeVideo = async () => {
    if (!video.processing) return
    try {
      await httpClient.instance.post(`encode/${video.id}`)
    } catch {
      sbOpen("Error pendant l'encodage", "error")
    } finally {
      refresh()
    }
  }

  return (
    <Grid container direction="row" key={video.id} borderTop="solid darkgrey 1px">
      <Grid container item direction="row" xs={3}>
        <Grid item xs={12}>
          <AspectRatioBox ratio={16 / 9}>
            {video.processing ? (
              <VideoPlayer id={video.id} raw autoplay={false} disableKeyboardShortcuts />
            ) : (
              <Typography
                textAlign="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                color="error"
              >
                {"Aucune vidéo uploadée"}
              </Typography>
            )}
          </AspectRatioBox>
        </Grid>
        <Grid item xs={12}>
          <AspectRatioBox ratio={16 / 9}>
            <VideoThumbnailImage video={video} />
          </AspectRatioBox>
        </Grid>
      </Grid>
      <Grid container item direction="column" xs={9}>
        <Typography component="h3" variant="h3" margin={1}>
          {video.title}
        </Typography>
        <Typography margin={1}>{video.date}</Typography>
        {video.processing < 2 ? (
          <>
            <Typography margin={1}>
              {video.description && video.description.length ? video.description : "Sans description"}
            </Typography>
            <Typography margin={1}>{"Accès : " + ["Publique", "CS", "Hyris", "Archivée"][video.privacy]}</Typography>
            <Typography margin={1}>
              {video.promotion && video.promotion > 0 ? "Promo : P" + video.promotion : "Aucune promo associée"}
            </Typography>
            <Typography margin={1}>
              {video.directId ? "DT associé : id = " + video.directId : "Aucun direct associé"}
            </Typography>
            <Grid item container direction="row">
              <Button
                sx={{ margin: 1 }}
                variant="outlined"
                color="secondary"
                onClick={() => history.push(`/editVideo/${video.id}`)}
              >
                {"Modifier"}
              </Button>
              {video.processing > 0 && (
                <Button sx={{ margin: 1 }} variant="contained" color="secondary" onClick={encodeVideo}>
                  {"Encoder et publier"}
                </Button>
              )}
              <Button
                variant="contained"
                color="error"
                sx={{ margin: 1 }}
                onClick={() => setDeletePopupOpen(true)}
                id={`delete-button${video.id}`}
              >
                {"Supprimer"}
              </Button>
              <Popup
                anchor={document.querySelector(`#delete-button${video.id}`)}
                open={isDeletePopupOpen}
                placement="top"
                offset={10}
              >
                <ClickAwayListener onClickAway={() => setDeletePopupOpen(false)}>
                  <Box padding={2} borderRadius="5px" sx={{ backgroundColor: "primary.main" }}>
                    <Typography>{"Etes-vous sûr de vouloir supprimer cette vidéo ?"}</Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ margin: 1 }}
                      onClick={() => setDeletePopupOpen(false)}
                    >
                      {"Annuler"}
                    </Button>
                    <Button variant="outlined" color="error" onClick={deleteVideo} sx={{ margin: 1 }}>
                      {"Oui, supprimer"}
                    </Button>
                  </Box>
                </ClickAwayListener>
              </Popup>
            </Grid>
          </>
        ) : (
          <Typography margin={1}>{"Encodage en cours..."}</Typography>
        )}
      </Grid>
    </Grid>
  )
}
