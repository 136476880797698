import { httpClient } from "../http"
import { formatSearch } from "../../components/Util"
import { TagType } from "./tag"

export interface VideoType {
  id: number
  title: string
  description: string
  processing: number
  privacy: number
  date: string
  views: number
  promotion?: number
  directId?: number
  likeCount?: number
  tagIds: number[]
}

export enum VideoQueryType {
  VIDEO,
  DIRECT,
  HISTORY,
  LIKED,
  SEARCH,
  LATEST,
  TRENDING,
  PENDING,
  RANDOM,
  MOST_LIKED,
  MOST_VIEWED,
}

export interface VideoQuery {
  constraint?: VideoQueryType
  id?: number
  search?: string
  exclude?: string
  page?: number
  pageSize?: number
  tag_filter?: TagType[]
}

export class PagedVideoResponse {
  videos: VideoType[] = []
  nResults = 0
}

export const getVideos = async (
  query: VideoQuery
): Promise<PagedVideoResponse> => {
  let param = "videos"
  const params = new URLSearchParams()

  switch (query.constraint) {
    case VideoQueryType.DIRECT:
      if (!query.id) return new PagedVideoResponse()
      param += `/direct/${query.id}`
      if (query.exclude) params.set("excluded_video", query.exclude)
      break

    case VideoQueryType.VIDEO:
      if (!query.id) return new PagedVideoResponse()
      param += `/${query.id}`
      break

    case VideoQueryType.LIKED:
      param += "/liked"
      break

    case VideoQueryType.SEARCH:
      param += `/search/${formatSearch(query.search || "")}`
      break

    case VideoQueryType.TRENDING:
      param += "/trending"
      break

    case VideoQueryType.PENDING:
      param += "/pending"
      break

    case VideoQueryType.RANDOM:
      param += "/random"
      break

    case VideoQueryType.MOST_LIKED:
      param += "/mostLiked"
      break

    case VideoQueryType.MOST_VIEWED:
      param += "/mostViewed"
      break

    case VideoQueryType.HISTORY:
      param += "/history"
      break

    default:
      break
  }

  if (query.page) params.set("page", String(query.page))
  if (query.pageSize) params.set("page_size", String(query.pageSize))
  if (query.tag_filter)
    query.tag_filter.map((tag) => params.append("tag_filter", String(tag.id)))

  const queryString = params.toString()
  return httpClient.instance.get<PagedVideoResponse>(
    queryString ? `${param}?${queryString}` : param
  )
}
