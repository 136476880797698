import { Typography } from "@mui/material"
import { Footer } from "../components/Footer"
import { HeaderBar } from "../components/HeaderBar"
import React from "react"
import { useAuthContext } from "../services/auth"
import { Main, PageTitle } from "../components/StyledElements"

const notFoundErrorTextPool = [
  "Cette page n'existe pas...",
  "Tous les chemins mènent à Rome. Sauf celui-là...",
  "Bah alors, on sait pas écrire un lien ?",
  "Et tu comptes aller où comme ça ?",
  "Ah, you're finally awake !",
  "= CCCLXVI + sqrt(1600) - log10(100)",
]

export const Limbo = (): React.ReactElement => {
  const { isAuth } = useAuthContext()
  return (
    <React.Fragment>
      <HeaderBar noSearchBar={!isAuth()} />
      <Main>
        <PageTitle>{"Erreur 404 !"}</PageTitle>
        <Typography component="h2" variant="h5" color="textPrimary" align="center" gutterBottom>
          {notFoundErrorTextPool[Math.floor(Math.max((Math.random() * 10 - 9) * notFoundErrorTextPool.length, 0))]}
        </Typography>
      </Main>
      <Footer />
    </React.Fragment>
  )
}
