import {useContext} from 'react';
import {SnackbarContextType, SnackbarContext} from '../snackbarContext';

export const useSnackbarContext = (): SnackbarContextType => {
  const authctx = useContext(SnackbarContext) as SnackbarContextType;
  if (!authctx) {
    console.error('no provider found, major error');
  }
  return authctx;
};
