import React, { useState } from "react"
import { Toolbar, Button, Typography, Box, AppBar } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { SideMenu } from "./SideMenu"
import { ReactComponent as MenuLogo } from "../assets/svg/menu.svg"
import { ReactComponent as LoginIcon } from "../assets/svg/log-in.svg"
import { ReactComponent as LogoutIcon } from "../assets/svg/log-out.svg"
import { useAuthContext } from "../services/auth"
import { useHistory } from "react-router-dom"
import { SearchBar } from "./SearchBar"

interface HeaderBarProps {
  noSearchBar?: boolean
  setSearchFocus?: (focused: boolean) => void
}

export const HeaderBar = ({ noSearchBar, setSearchFocus }: HeaderBarProps): React.ReactElement => {
  const { isAuth, logout } = useAuthContext()
  const [openSideMenu, toggleSideMenu] = useState(false)
  const history = useHistory()
  const theme = useTheme()

  const handleLoginClick = () => {
    if (isAuth()) {
      logout()
    }
    history.push("/login")
  }

  return (
    <React.Fragment>
      <AppBar position="sticky">
        <SideMenu open={openSideMenu} onClose={() => toggleSideMenu(false)} />
        <Toolbar
          sx={{
            borderBottom: 1,
            bgcolor: theme.palette.element.header.background,
            alignContent: "center",
            justifyContent: "space-between",
            p: 0,
          }}
        >
          <Box maxHeight={80} maxWidth={80}>
            <Button
              color="inherit"
              onClick={() => toggleSideMenu(true)}
              sx={{ p: "20%", maxHeight: "80px", maxWidth: "80px" }}
            >
              <MenuLogo
                style={{
                  stroke: theme.palette.primary.contrastText,
                }}
                width="100%"
                height="100%"
              />
            </Button>
          </Box>
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            align="center"
            noWrap
            sx={{
              flex: 1,
            }}
          >
            {noSearchBar ? <></> : <SearchBar setSearchFocus={setSearchFocus} />}
          </Typography>
          <Box maxHeight={80} maxWidth={80}>
            <Button color="inherit" onClick={handleLoginClick} sx={{ p: "25%", maxHeight: "80px", maxWidth: "80px" }}>
              {isAuth() ? (
                <LogoutIcon
                  style={{
                    stroke: theme.palette.element.header.icon,
                    strokeWidth: 1,
                  }}
                  width="100%"
                  height="100%"
                />
              ) : (
                <LoginIcon
                  style={{
                    stroke: theme.palette.element.header.icon,
                    strokeWidth: 1,
                  }}
                  width="100%"
                  height="100%"
                />
              )}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}
